import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default WrappedComponent => () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.session);

  window.addEventListener('storage', event => {
    dispatch.session.onStorageChange(event);
  });

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch.session.askForSharedCredentials();
    }
  }, [dispatch.session, isAuthenticated]);

  return <WrappedComponent />;
};
