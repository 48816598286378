import { init } from '@rematch/core';

import contentModel from './content';
import ltiModel from './lti';
import standaloneAppModel from './standaloneapp';
import navigationModel from './navigation';
import searchModel from './search';
import sessionModel from './session';
import userModel from './user';

const store = state => ({
  models: {
    content: contentModel(state),
    lti: ltiModel(state),
    standaloneapp: standaloneAppModel(state),
    navigation: navigationModel(state),
    search: searchModel(state),
    session: sessionModel(state),
    user: userModel(state),
  },
});

const initStore = (initialState = {}) => init(store(initialState));

export default initStore;
