import isUndefined from 'lodash/isUndefined';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { P } from '../typography';
import getSearchResults from '../../utils/selectors/getSearchResults';

const StyledSummary = styled(P)`
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const searchSummary = ({ currentPage, found, pageCount, perPage }) => {
  if (isUndefined(found)) return 'Press enter to view all results.';

  if (found === 0) return 'Your search returned no results.';

  if (pageCount === 1) {
    return `Showing ${found} results.`;
  }

  const min = (currentPage - 1) * perPage + 1;
  const max = Math.min(min + perPage - 1, found);

  return `Showing ${min}–${max} of ${found} results.`;
};

export default () => {
  const { found, currentPage, perPage, query } = useSelector(getSearchResults);

  const pageCount = Math.ceil(found / perPage);

  const message = searchSummary({ found, currentPage, perPage, pageCount });

  return (
    <div aria-live="polite" aria-atomic="true" key={`${query}${currentPage}`} aria-hidden={isUndefined(found)}>
      <StyledSummary data-testid="search-summary">{message}</StyledSummary>
    </div>
  );
};
