import Api from './api';
import initCognito from '../init-cognito';

class AuthenticatedApi extends Api {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/authenticated`;
  }

  async get({ path, params }) {
    return this.request({ path, params, method: 'GET' });
  }

  async patch({ path, data = {} }) {
    return this.request({ path, data, method: 'PATCH' });
  }

  async post({ path, data = {} }) {
    return this.request({ path, data, method: 'POST' });
  }

  async request({ path, params, ...args }) {
    return new Promise((resolve, reject) => {
      const cognito = initCognito();
      cognito.userhandler = {
        onSuccess: newSession => {
          this.makeRequest(`${this.baseUrl}/${path}/${Api.buildQuery(params)}`, {
            headers: {
              Authorization: newSession.getIdToken().getJwtToken(),
            },
            ...args,
          })
            .then(({ data }) => resolve(data))
            .catch(error => {
              Api.handleError(error)
                .then(result => resolve(result))
                .catch(reject);
            });
        },
        onFailure: err => {
          // Failed to refresh token, clear local cache
          cognito.clearCachedTokensScopes();
          reject(err);
        },
      };
      /**
       * Ideally a single `cognito.getSession()` should do this job.
       * However when refreshing token it cannot find `this.userhandler`
       * So we have to define the flow as following as a workaround.
       */
      const signInUserSession = cognito.getCachedSession();
      if (signInUserSession.isValid()) {
        cognito.userhandler.onSuccess(signInUserSession);
      } else if (signInUserSession.getRefreshToken() && signInUserSession.getRefreshToken().getToken()) {
        cognito.refreshSession(signInUserSession.getRefreshToken().getToken());
      } else {
        cognito.getSession();
      }
    });
  }
}

export default new AuthenticatedApi();
