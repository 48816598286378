import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { ifProp } from 'styled-tools';
import { Col } from '../grid';
import Link from '../link';
import { styles } from '../typography';

const FooterNav = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const FooterNavItem = styled.li`
  ${styles.font()};

  font-size: 16px;
  color: #ffffff;
  margin-bottom: 1rem;

  a {
    color: #ffffff;
    font-weight: ${ifProp('bold', 'bold', 'regular')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ChildNavItem = ({ url, text, target }) => (
  <FooterNavItem>
    <Link to={url} target={target}>
      {text}
    </Link>
  </FooterNavItem>
);

ChildNavItem.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
};

ChildNavItem.defaultProps = {
  target: '_self',
};

export default ({ navigations = [] }) => {
  return navigations.map(({ text, url, target, children = [] }) => (
    <Col width={{ xs: 1 / 2, md: 2 / 12 }} key={text}>
      <FooterNav>
        <FooterNavItem bold>
          <Link to={url} target={target}>
            {text}
          </Link>
        </FooterNavItem>
        {children.map(child => (
          <ChildNavItem key={child.text} {...child} />
        ))}
      </FooterNav>
    </Col>
  ));
};
