import { useDispatch } from 'react-redux';

const useSearch = () => {
  const dispatch = useDispatch();

  return query => {
    if (query) {
      dispatch.search.fetch({
        query,
      });
    }
  };
};

export default useSearch;
