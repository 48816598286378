import { get } from '../../api';

/**
 * The content model for standalone apps.
 * @param {Object} params
 * @param {Object} params.standaloneapp
 * @returns {Object} The content model object with state, reducers and effects.
 */
const contentModel = ({ standaloneapp = {} }) => ({
  state: standaloneapp,
  reducers: {
    clear: () => ({}),
    update: (state, { standaloneAppsType, data }) => ({ ...state, [standaloneAppsType]: data }),
  },

  effects: dispatch => ({
    /**
     * Fetches the standalone app data and updates the state.
     * @param {Object} payload The payload containing the standalone app type.
     * @param {string} payload.standaloneAppsType The type of the standalone app.
     */
    async fetch({ standaloneAppsType }) {
      const { data } = await get({ path: `standaloneapp/${standaloneAppsType}` });
      dispatch.standaloneapp.update({ standaloneAppsType, data });
    },
  }),
});

export default contentModel;
