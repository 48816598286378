import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Title from '../../../components/title';
import Body from '../../../components/body';
import Layout from '../../../layouts';
import TilesBlock from '../../../components/tiles-block';
import getContent from '../../../utils/selectors/getContent';
import useFetch from '../../../utils/useFetch';

const fetchOpts = {
  override: {
    title: 'Tile preview page',
    body: '<p>This view shows how this page will look like when linked into a tiles block.</p>',
  },
};

const Page = ({ path }) => {
  const dispatch = useDispatch();
  const isFetching = useFetch(path, fetchOpts);

  const { tileHeading, tileBody } = useSelector(getContent);

  useEffect(() => {
    // Remove path prefix "/preview/page-as-tiles/"
    dispatch.content.setCurrentPath(_.takeRight(_.split(path, '/')));
  }, [dispatch.content, path]);

  if (isFetching) return null;

  const props = {
    heading: 'The tiles block for demonstration',
    body: '<p>The current page will be rendered as the following tile once linked.</p>',
    tiles: [
      {
        id: 1,
        heading: tileHeading,
        body: tileBody,
        url: '#',
      },
    ],
  };

  return (
    <Layout>
      <Title />
      <Body />
      <TilesBlock {...props} />
    </Layout>
  );
};

Page.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Page;
