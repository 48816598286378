import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { isFunction, isUndefined, omitBy } from 'lodash';

import Link from '../link';
import { keyboardFocusHighlight, normalise } from '../../utils/css';
import { post } from '../../utils/api';

import { styles } from '../typography';

export const ButtonProps = {
  analytics: PropTypes.shape({
    action: PropTypes.string,
    category: PropTypes.string.isRequired,
    heading: PropTypes.string,
    label: PropTypes.string,
    tag: PropTypes.string,
    value: PropTypes.string,
  }),
  children: PropTypes.node,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  src: PropTypes.string,
  variant: PropTypes.string,
};

const Wrapper = styled.button`
  ${normalise};

  align-items: center;
  background: none;
  cursor: pointer;
  display: flex;
`;

const LinkElement = styled(Link)`
  ${normalise};
  align-items: center;
  background: ${props => props.theme.white};
  box-sizing: border-box;
  color: ${props => props.theme.brandColor};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  width: 100%;

  ${keyboardFocusHighlight()};

  &:hover {
    text-decoration: none;

    g {
      fill: ${props => props.theme.brandColor};
    }
  }
`;

const StyledButton = styled(LinkElement)`
  ${styles.font({ weight: 'semibold' })};

  background: ${props => props.theme.brandColor};
  border: 1px solid ${props => props.theme.brandColor};
  border-radius: 4px;
  color: ${props => props.theme.white};
  font-size: 18px;
  min-height: 3rem;
  padding: 1rem 3rem;
  width: 100%;

  &:hover {
    background: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.brandColor};
    color: ${props => props.theme.brandColor};
  }

  ${ifProp(
    'disabled',
    css`
      background: ${props => props.theme.disabledColor};
      border: 1px solid ${props => props.theme.disabledColor};
      color: ${props => props.theme.white};
      cursor: not-allowed;
    `
  )}

  ${ifProp(
    'small',
    css`
      font-size: 14px;
      margin: 0;
      padding: 0 0.5rem;
    `
  )}
`;

const ModalButton = styled(StyledButton).attrs(() => ({ as: 'button' }))`
  background-color: ${props => props.theme.brandColor};
  display: flex;

  &:disabled {
    background-color: ${props => props.theme.disabledColor};
  }
`;

const NavigationButton = styled(({ isActive, ...props }) => <LinkElement {...props} />)`
  ${styles.font({ weight: 'semibold' })};
  color: ${props => props.theme.darkGrey};
  font-size: 14px;
  height: 100%;
  margin: 0;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.brandColor};
    color: ${props => props.theme.white};
    text-decoration: none;
  }

  ${ifProp(
    'isActive',
    css`
      background-color: ${props => props.theme.brandColor};
      color: ${props => props.theme.white};
      text-decoration: none;
    `
  )}
`;

const ActionButton = styled(StyledButton).attrs(() => ({ as: 'button' }))`
  background-color: ${props => props.theme.brandColor};
  display: flex;

  &:disabled {
    background-color: ${props => props.theme.disabledColor};
  }
`;

const IconButton = styled(ActionButton)`
  background-color: transparent;
  border: none;
  font-size: 2rem;
  line-height: 0;
  width: initial;

  /* set a negative margin and a positive padding to enlarge the clickable
  area (for accessibility purposes) without affecting visual layout */
  margin: -1rem;
  padding: 1rem;

  path {
    fill: ${props => props.theme.brandColor};
  }

  &:disabled {
    background-color: transparent;
    border: none;

    path {
      fill: ${props => props.theme.disabledColor};
    }

    cursor: not-allowed;
  }

  &:hover {
    background: none;
    border: none;
  }
`;

const onButtonClick = ({ analytics, originalFn }) => (...args) => {
  if (analytics) {
    post({ path: 'events', data: omitBy(analytics, isUndefined) });
  }

  if (isFunction(originalFn)) {
    originalFn(...args);
  }
};

const Button = ({ analytics, children, small, text, url, src, variant, onClick, ...props }) => {
  let ButtonComponent = StyledButton;

  if (variant === 'navigation') {
    ButtonComponent = NavigationButton;
  }

  if (variant === 'icon') {
    ButtonComponent = IconButton;
  }

  if (variant === 'action') {
    ButtonComponent = ActionButton;
  }

  if (variant === 'wrapper') {
    ButtonComponent = Wrapper;
  }

  if (variant === 'modal-panel') {
    ButtonComponent = ModalButton;
  }

  return (
    <ButtonComponent
      small={small}
      to={url || src}
      onClick={onButtonClick({ analytics, originalFn: onClick })}
      {...props}
    >
      {text || children}
    </ButtonComponent>
  );
};

Button.propTypes = ButtonProps;
Button.defaultProps = {
  analytics: undefined,
  children: undefined,
  onClick: undefined,
  small: undefined,
  target: undefined,
  text: undefined,
  url: undefined,
  src: undefined,
  variant: '',
};

export default Button;
