import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { post } from '../../utils/api';

const onExternalLinkClicked = ({ originalFn, href }) => (...args) => {
  post({ path: 'events', data: { category: 'external link clicked', value: href } });

  if (isFunction(originalFn)) {
    originalFn(...args);
  }
};

/* Returns a Link object that will alternate between a React-Router Link
element (for internal links) and standard <a> tags (for external links) */
const Link = ({ children, download, to, small, onClick, ...props }) => {
  // all internal links will start with '/' -- this is enforced by the API
  // if / is missing, we can assume an external link
  // in the event we incorrectly match an internal link, this is a safe fallback
  if (!to.startsWith('/') || download) {
    return (
      <a
        href={to}
        data-testid={`anchor-${to}`}
        {...props}
        download={download}
        onClick={onExternalLinkClicked({
          originalFn: onClick,
          href: to,
        })}
      >
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={to} data-testid={`link-${to}`} onClick={onClick} {...props}>
      {children}
    </ReactRouterLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  download: PropTypes.bool,
  onClick: PropTypes.func,
  /* capture `small` here so that it doesn't get passed down to child components  */
  small: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  download: undefined,
  onClick: undefined,
  small: undefined,
};

export default Link;
