import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import { Container, Col, Row } from '../../components/grid';
import Button from '../../components/button';
import { P, styles } from '../../components/typography';
import Layout from '../../layouts';
import Body from '../../components/body';
import Title from '../../components/title';
import Link from '../../components/link';
import useFetch from '../../utils/useFetch';
import getContent from '../../utils/selectors/getContent';
import Image from '../../components/image';

const StaffLogin = styled(Button).attrs(() => ({ variant: 'action' }))`
  ${styles.font({ weight: 'semibold' })};

  background: transparent;
  border: none;
  color: ${props => props.theme.darkGrey};
  display: inline-block;
  flex-direction: column;
  font-size: 1rem;
  padding: 0;
  position: relative;
  text-decoration: underline;

  &:hover {
    background: transparent;
    border: none;
    color: ${props => props.theme.brandColor};
    text-decoration: underline;
  }
`;

const CentredCol = styled(Col)`
  text-align: center;
`;

const ErrorMessage = styled(P)`
  color: ${props => props.theme.secondaryColor};
  margin-bottom: 2rem;
`;

const titleCSS = css`
  margin-bottom: 0;
  padding: 1rem 0;
  text-align: center;
`;

const passwordResetLinkCSS = css`
  ${styles.font()};
  font-size: 0.8rem;
  margin-bottom: 2rem;
`;

const passwordResetRowCSS = css`
  margin-bottom: 3rem;
`;

const fetchOpts = { requireAuth: false };

const transformError = error =>
  _.replace(
    _.replace(error, 'Error in SAML response processing: PostAuthentication failed with error ', ''),
    /\.\./,
    '.'
  );

const Login = () => {
  const dispatch = useDispatch();

  const login = () => {
    dispatch.session.signIn('student');
  };

  const staffLogin = () => {
    dispatch.session.signIn('staff');
  };

  useFetch('login', fetchOpts);

  const { error } = useSelector(state => state.session);
  const { image, link } = useSelector(getContent);

  return (
    <Layout>
      <Container>
        <Row>
          <Col width={{ xs: 1, md: 4 / 12 }} offset={{ xs: 0, md: 4 }}>
            {image && <Image {...{ ...image, sources: [] }} />}
          </Col>
        </Row>
      </Container>
      <Title css={titleCSS} width={{ xs: 1, lg: 8 / 12 }} offset={{ xs: 0, lg: 2 }} />
      <Body center width={{ xs: 1, lg: 6 / 12 }} offset={{ xs: 0, lg: 3 }} />
      {error && <ErrorMessage data-testid="error">{transformError(error)}</ErrorMessage>}
      <Container>
        <Row>
          <Col width={{ xs: 1, md: 6 / 12, lg: 5 / 12, xl: 4 / 12 }} offset={{ xs: 0, md: 3, lg: 3.5, xl: 4 }}>
            <Button as="button" data-testid="login" onClick={login}>
              Log in
            </Button>
          </Col>
        </Row>
        {link && (
          <Row css={passwordResetRowCSS}>
            <CentredCol width={{ xs: 1, md: 4 / 12 }} offset={{ xs: 0, md: 4 }}>
              <Link to={link.url} target={link.target} css={passwordResetLinkCSS}>
                {link.text}
              </Link>
            </CentredCol>
          </Row>
        )}
        <Row>
          <CentredCol width={{ xs: 1, md: 4 / 12 }} offset={{ xs: 0, md: 4 }}>
            <StaffLogin data-testid="staff-login" onClick={staffLogin}>
              Staff Login
            </StaffLogin>
          </CentredCol>
        </Row>
      </Container>
    </Layout>
  );
};

export default Login;
