import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import DispatchButton from '../dispatch-button';
import { Col, Row } from '../grid';
import { AssetProps } from '../image';
import ProcessItem from '../process-item';
import getContent from '../../utils/selectors/getContent';

export const AccordionProps = {
  body: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape(AssetProps),
  video: PropTypes.shape(AssetProps),
};

const ButtonContainer = styled(Col).attrs(() => ({
  width: { xs: 1, md: 4 / 12, lg: 3 / 12 },
  offset: { xs: 0, md: 8, lg: 9 },
}))`
  display: flex;
  justify-content: left;
`;

const ProcessBlock = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { processBlock } = useSelector(getContent);

  if (!processBlock) return null;

  return (
    <div data-testid="process-block">
      {processBlock.map((item, index) => {
        const active = index === activeIndex;

        return (
          <Row key={`${item.id}-${active}`} width={1}>
            <Col width={1}>
              <ProcessItem
                {...item}
                active={active}
                completed={activeIndex > index}
                index={index}
                onClick={() => {
                  setActiveIndex(index + 1);
                }}
              />
            </Col>
          </Row>
        );
      })}
      {activeIndex === processBlock.length && (
        <Row width={1}>
          <ButtonContainer>
            <DispatchButton
              data-testid="process-complete-button"
              payload={{ orientationAccomplished: true }}
              css={css`
                max-width: unset;
              `}
            >
              Done
            </DispatchButton>
          </ButtonContainer>
        </Row>
      )}
    </div>
  );
};

export default ProcessBlock;
