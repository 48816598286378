import isFunction from 'lodash/isFunction';

const STORAGE_TYPE_SESSION = 'session';

const STORAGE_TYPE_LOCAL = 'local';

const getStorage = type => {
  try {
    switch (type) {
      case STORAGE_TYPE_SESSION:
        return sessionStorage;
      case STORAGE_TYPE_LOCAL:
        return localStorage;
      default:
        return null;
    }
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    return null;
  }
};

export const getSessionStorage = cb => {
  const storage = getStorage(STORAGE_TYPE_SESSION);
  if (storage && isFunction(cb)) {
    cb(storage);
  }

  return storage;
};

export const getLocalStorage = cb => {
  const storage = getStorage(STORAGE_TYPE_LOCAL);
  if (storage && isFunction(cb)) {
    cb(storage);
  }

  return storage;
};
