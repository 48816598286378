import 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Box } from '@rebass/grid';

import { breakpoints } from '../../constants';

const MAX_WIDTH = _.max(_.values(breakpoints));

const Container = ({ children, ...props }) => (
  <Box
    css={`
      width: 100%;
      max-width: ${MAX_WIDTH}px;
    `}
    mx="auto"
    px={{ xs: '1.5rem', md: '2rem' }}
    {...props}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
