import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useFetch = (path, opts) => {
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    let isMounted = true;

    dispatch.content.fetch({
      path,
      callback: () => {
        if (isMounted) setIsFetching(false);
      },
      ...opts,
    });

    return () => {
      isMounted = false;
    };
  }, [path, dispatch.content, opts]);

  return isFetching;
};

export default useFetch;
