import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import breakpoint from 'styled-components-breakpoint';

import OnlineClassroomTile from '../online-classroom-tile';
import { ButtonProps } from '../button';

const OnlineClassroomLinks = styled.div`
  display: none;

  ${breakpoint('lg')`
    display: flex;
    display: grid;
    grid: 1fr / auto-flow minmax(min-content, calc(100%/3));
  `}
`;

/**
 * Online Classroom Button
 *
 * This component will render an array of OnlineClassroomTile components in the Header
 * *
 * @param {array} buttons linkit object array
 */
const OnlineClassroom = ({ buttons }) => (
  <OnlineClassroomLinks css={css``}>
    {buttons.map(({ text, url, target }) => (
      <OnlineClassroomTile key={url} url={url} title={text} target={target} />
    ))}
  </OnlineClassroomLinks>
);

OnlineClassroom.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape(ButtonProps)),
};

OnlineClassroom.defaultProps = {
  buttons: [],
};

export default OnlineClassroom;
