import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Col, Row } from '../grid';
import RichText from '../rich-text';
import getContent from '../../utils/selectors/getContent';

const StyledRichText = styled(RichText)`
  margin-bottom: 2rem;
`;

// eslint-disable-next-line react/prop-types
const Body = ({ offset, width, ...props }) => {
  const { body } = useSelector(getContent);

  if (!body) return null;

  return (
    <Row>
      <Col {...{ offset, width }}>
        <StyledRichText data-testid="body" {...props}>
          {body}
        </StyledRichText>
      </Col>
    </Row>
  );
};

export default Body;
