import { get } from '../../api';

const navigationModel = ({ navigation = { isVisible: false } }) => ({
  state: navigation,
  reducers: {
    toggle: state => ({
      ...state,
      isVisible: !state.isVisible,
    }),
    hide: state => ({
      ...state,
      isVisible: false,
    }),
    update: (state, payload) => payload,
  },
  effects: dispatch => ({
    async fetch() {
      const response = await get({ path: `navigation`, requireAuth: true });

      const { data } = response;

      dispatch.navigation.update(data);
    },
  }),
});

export default navigationModel;
