import URLSearchParams from '@ungap/url-search-params';

/**
 * Appends new `key: value` pairs to the URL Search Params requested by the browser.
 *
 * This allows us to inherit parameters passed in through a parent `iframe` and pass
 * them on to an API request, along with any additional keys to be defined in the client.
 *
 * @param {params} object - An object containing `{key: value}` pairs for query parameters
 * @return a formatted query string, including the leading `?`
 * */

export default (params = {}) => {
  const urlParams = new URLSearchParams(window.location.search);

  const keys = Object.keys(params);

  keys.map(key => urlParams.set(key, params[key]));

  if (urlParams.get('site') === 'default') {
    urlParams.delete('site');
  }

  const paramString = urlParams.toString();

  return paramString ? `?${paramString}` : '';
};
