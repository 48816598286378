import React from 'react';
import styled from 'styled-components/macro';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@rebass/grid';
import { Route, Switch } from 'react-router-dom';

import Content from '../../pages/content';
import Footer from '../../components/footer';
import Header from '../../components/header';
import ImpersonationBanner from '../../components/impersonation-banner';
import ImpersonationModal from '../../components/impersonation-modal';
import Search from '../../pages/search';

const SEARCH_PAGE = '/search';

const Body = styled(Flex)`
  background-color: ${props => props.theme.backgroundColor};
  flex-direction: column;
  min-height: 100vh;
`;

const ContentPage = () => {
  const dispatch = useDispatch();
  const modalVisibility = useSelector(state => state.content.impersonationModalVisibility);

  return (
    <>
      <ImpersonationBanner />
      <Header />
      <Body>
        <Switch>
          <Route exact path="/" render={() => <Content path="home" />} />
          <Route path={SEARCH_PAGE} render={() => <Search />} />
          <Route path="/:path" render={({ match: { params } }) => <Content path={params.path} />} />
        </Switch>
      </Body>
      <Footer />
      <ImpersonationModal
        handleClose={() => dispatch.content.setImpersonationModalVisibility(false)}
        show={!isNil(modalVisibility) ? modalVisibility : false}
      />
    </>
  );
};

export default ContentPage;
