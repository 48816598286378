import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import breakpoint from 'styled-components-breakpoint';

import PrimaryNavigationTile from '../primary-navigation-tile';
import { ButtonProps } from '../button';

const NavigationList = styled.div`
  display: none;

  ${breakpoint('lg')`
    display: flex;
    display: grid;
    grid: 1fr / auto-flow minmax(min-content, calc(100%/3));
  `}
`;

const SkipNavigationLink = styled.a`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  position: absolute;
  overflow: hidden;
  width: 1px;

  &:focus {
    clip: auto;
    height: auto;
    width: auto;
  }
`;

/**
 * Primary Navigation
 *
 * This component will render an array of PrimaryNavigationTile components in the Header
 * *
 * @param {string} TBD
 */
const PrimaryNavigation = ({ navigations }) => (
  <NavigationList
    css={css`
      height: 100%;
      padding: 0;
    `}
  >
    <SkipNavigationLink href="#mainContent">Skip Navigation</SkipNavigationLink>
    {navigations.map(({ text, url, target }) => (
      <PrimaryNavigationTile key={url} url={url} title={text} target={target} />
    ))}
  </NavigationList>
);

PrimaryNavigation.propTypes = {
  navigations: PropTypes.arrayOf(PropTypes.shape(ButtonProps)),
};

PrimaryNavigation.defaultProps = {
  navigations: [],
};

export default PrimaryNavigation;
