import floor from 'lodash/floor';
import map from 'lodash/map';
import slice from 'lodash/slice';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { styles } from '../typography';
import usePagination from '../../utils/usePagination';
import Button from '../button';
import { Col, Container, Row } from '../grid';
import { ReactComponent as FirstArrow } from '../../icons/first.svg';
import { ReactComponent as LastArrow } from '../../icons/last.svg';
import { ReactComponent as NextArrow } from '../../icons/next.svg';
import { ReactComponent as PrevArrow } from '../../icons/prev.svg';

const PAGINATION_COUNT = 5;

const ArrowWrapper = styled(Button).attrs(() => ({ variant: 'wrapper' }))`
  width: 2rem;
  height: 2rem;

  path {
    fill: ${({ disabled, theme }) => (disabled ? theme.disabledColor : theme.brandColor)};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const PageNumber = styled(Button).attrs({ variant: 'wrapper', weight: 'semibold' })`
  ${props => styles.p(props)}
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  line-height: 0;
  transition: all 100ms ease-in-out;
  width: 2rem;

  color: ${ifProp(
    'isActive',
    css`
      ${props => props.theme.white}
    `,
    css`
      ${props => props.theme.darkGrey}
    `
  )};

  background-color: ${ifProp(
    'isActive',
    css`
      ${props => props.theme.brandColor}
    `,
    'transparent'
  )};

  &:hover {
    background-color: ${props => props.theme.brandColor};
    color: ${props => props.theme.white};
  }
`;

const PaginationCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
`;

// We only want to display PAGINATION_COUNT page links at any given time.
// `getVisiblePages` first determines which 'set' of page links the current page belongs to,
// and then returns an array containing only those links.
// eg if we are on page 7, getVisiblePages will return [6,7,8,9,10]
const getVisiblePages = (pages, currentPage) => {
  const offset = floor((currentPage - 1) / PAGINATION_COUNT) * PAGINATION_COUNT;

  return slice(pages, offset, offset + PAGINATION_COUNT);
};

const Pagination = () => {
  const { currentPage, decrement, increment, pages, select } = usePagination();

  if (pages.length <= 1) return null;

  const visiblePages = getVisiblePages(pages, currentPage);

  return (
    <Container>
      <Row width={1}>
        <PaginationCol width={{ xs: 1, lg: 6 / 12 }} offset={{ xs: 0, lg: 3 }} data-testid="search-pagination">
          <ArrowWrapper
            data-testid="first-page"
            onClick={() => select(1)}
            disabled={currentPage === 1}
            aria-label="View First Page of Search Results"
          >
            <FirstArrow />
          </ArrowWrapper>
          <ArrowWrapper
            data-testid="decrement-pagination"
            onClick={decrement}
            disabled={currentPage === 1}
            aria-label="View Previous Page of Search Results"
          >
            <PrevArrow />
          </ArrowWrapper>
          {map(visiblePages, page => (
            <PageNumber
              key={page}
              data-testid={`page-${page}`}
              isActive={page === currentPage}
              onClick={() => select(page)}
              aria-label={`View Search Results Page ${page}`}
            >
              {page}
            </PageNumber>
          ))}
          <ArrowWrapper
            data-testid="increment-pagination"
            onClick={increment}
            disabled={currentPage === pages.length}
            aria-label="View Next Page of Search Results"
          >
            <NextArrow />
          </ArrowWrapper>
          <ArrowWrapper
            data-testid="last-page"
            onClick={() => select(pages.length)}
            disabled={currentPage === pages.length}
            type="button"
            aria-label="View Last Page of Search Results "
          >
            <LastArrow />
          </ArrowWrapper>
        </PaginationCol>
      </Row>
    </Container>
  );
};

export default Pagination;
