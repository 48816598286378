import { get } from '../../api';

const contentModel = ({ lti = {} }) => ({
  state: lti,
  reducers: {
    clear: () => ({}),
    update: (state, { ltiType, data }) => ({ ...state, [ltiType]: data }),
    updateStat: (state, { ltiType, data }) => ({ ...state, [`${ltiType}Stat`]: data }),
  },
  effects: dispatch => ({
    async fetch({ ltiType }) {
      const { data } = await get({ path: `lti/${ltiType}` });
      dispatch.lti.update({ ltiType, data });
    },
    async stat({ ltiType }) {
      const { data } = await get({ path: `lti/${ltiType}/user/stat` });
      dispatch.lti.updateStat({ ltiType, data });
    },
  }),
});

export default contentModel;
