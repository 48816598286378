import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';

import Link from '../link';
import { H4, P } from '../typography';
import { keyboardFocusHighlight } from '../../utils/css';

export const TileProps = {
  id: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

const StyledLink = styled(Link)`
  color: black;

  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled(Flex)`
  background-color: ${props => props.theme.white};
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0.5rem;
  min-height: 12rem;
  padding: 1rem;

  ${H4} {
    padding-bottom: 1rem;
  }

  &:hover {
    border: 1px solid ${props => props.theme.brandColor};

    ${H4} {
      color: ${props => props.theme.brandColor};
    }
  }

  ${keyboardFocusHighlight(1)};
`;

/**
 * Tile
 *
 * This component will render a heading and body for articles.
 * The Tile will link through to the selected article.
 *
 * @param {string} body a short excerpt or description of the linked article
 * @param {string} url URL for the article
 * @param {string} heading the heading of the article
 */

const generateTestid = (obj, url) => `tile-${obj}-${url.replace(/(^[/])/g, '')}`;

const Tile = ({ id, body, url, heading }) => (
  <StyledLink data-testid={`tile-link-${id}`} to={url}>
    <Wrapper>
      <H4 data-testid={generateTestid('heading', url)}>{heading}</H4>
      <P small data-testid={generateTestid('body', url)}>
        {body}
      </P>
    </Wrapper>
  </StyledLink>
);

Tile.propTypes = TileProps;

export default Tile;
