import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Breadcrumbs from '../../components/breadcrumbs';
import { Container } from '../../components/grid';

const StyledContainer = styled(Container)`
  flex-grow: 1;
`;

const PageLayout = ({ children }) => (
  <>
    <StyledContainer>
      <Breadcrumbs />
      {children}
    </StyledContainer>
  </>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
