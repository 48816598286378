import get from 'lodash/get';
import includes from 'lodash/includes';

import transformTps from './transform-tps';

export default ({ user }) => ({
  ...user,
  isAdmin: includes(user.roles, 'admin'),
  activeTps: transformTps(get(user, 'impersonatedAs.activeTps', user.activeTps)),
});
