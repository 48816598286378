import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import axios from 'axios';

import RichText from '../rich-text';
import { AssetProps } from '../image';

const Wrapper = styled(RichText)`
  height: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;

  iframe,
  video {
    width: 100%;
    border: 0;
    object-fit: cover;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ fileName, poster }) => {
  const [code, setCode] = useState(null);

  const isEmbedded = fileName.endsWith('json');

  useEffect(() => {
    const fetchVideo = async () => {
      if (isEmbedded) {
        const { data } = await axios.get(fileName);
        setCode(data.code);
      }
    };

    fetchVideo();
  }, [fileName, isEmbedded]);

  if (!isEmbedded) {
    return (
      <Wrapper as="div">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls poster={get(poster, 'fileName', undefined)}>
          <source data-testid="video-element" src={fileName} type="video/mp4" />
        </video>
      </Wrapper>
    );
  }

  if (!code) return null;

  return <Wrapper>{code}</Wrapper>;
};

Video.propTypes = {
  fileName: PropTypes.string.isRequired,
  poster: PropTypes.shape(AssetProps),
};

Video.defaultProps = {
  poster: null,
};

export default Video;
