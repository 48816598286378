import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

import defaultTheme from './default';
import constants from '../../constants';

const ThemeProvider = ({ children }) => {
  const theme = process.env.REACT_APP_PARTNER_THEME || 'default';

  // Dynamically load the theme file.
  let themeObject;
  try {
      themeObject = require(`./${theme}`).default; // eslint-disable-line
  } catch (err) {
    console.log('Error using theme', theme, err); // eslint-disable-line no-console
    themeObject = defaultTheme;
  }

  // Combine theme with constants required by rebass.
  const combinedThemeObject = { ...themeObject, ...constants };

  return <StyledThemeProvider theme={combinedThemeObject}>{children}</StyledThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
