import React from 'react';
import PropTypes from 'prop-types';

import PageAsTiles from './page-as-tiles';

const Preview = ({ type, path }) => {
  switch (type) {
    case 'page-as-tiles':
      return <PageAsTiles path={path} />;
    default:
      return null;
  }
};

Preview.propTypes = {
  type: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default Preview;
