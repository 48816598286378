const fonts = {
  sans: '"Open Sans", "sans-serif"',
};

const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
};

export { fonts, fontWeights };
