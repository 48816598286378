import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { fontWeights } from '../../constants';
import { styles } from '../typography';

const getHtml = html => ({ __html: html });

const StyledRichText = styled.div`
  * ~ * {
    margin-top: 1rem;
  }

  ${props => styles.p(props)};

  * {
    ${props => styles.p(props)};
  }

  text-align: ${ifProp('center', 'center', 'left')};

  td,
  th {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
  }

  th {
    font-weight: ${fontWeights.semibold};
  }

  b,
  strong {
    ${styles.b()}
  }

  i,
  em {
    font-style: italic;
  }

  blockquote {
    font-style: italic;
    padding: 0 2rem;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
    font-weight: inherit;
  }

  li {
    list-style-position: outside;
    list-style-type: disc;
    margin-left: 1rem;
    padding-left: 0.5rem;
  }

  ol > li {
    list-style-type: decimal;

    ol > li {
      list-style-type: lower-alpha;
    }
  }

  h1 {
    ${styles.h1()}
  }

  h2 {
    ${styles.h2()}
  }

  h3 {
    ${styles.h3()}
  }

  h4 {
    ${styles.h4()}
  }

  h5 {
    ${styles.h5()}
  }
`;

/**
 * Rich Text
 *
 * This component accepts raw HTML text and displays it using styled components.
 *
 * @param {string} children HTML text to be rendered using dangerouslySetInnerHTML
 */
const RichText = ({ children, ...props }) => (
  <StyledRichText dangerouslySetInnerHTML={getHtml(children)} {...props} /> // eslint-disable-line react/no-danger
);

RichText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RichText;
