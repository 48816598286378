import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

import Link from '../link';

const Ul = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const Li = styled.li`
  float: right;

  font-size: 32px;
  color: #ffffff;
  margin-left: 20px;

  a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
      /* color: ${props => props.theme.brandColor};*/
    }
  }
`;

const SocialNav = ({ socialLinks }) => (
  <Ul>
    {socialLinks.map(link => {
      let socialComponent;
      switch (link.type) {
        case 'facebook':
          socialComponent = <FaFacebook />;
          break;
        case 'instagram':
          socialComponent = <FaInstagram />;
          break;
        case 'linkedin':
          socialComponent = <FaLinkedin />;
          break;
        default:
          socialComponent = null;
      }

      return (
        socialComponent && (
          <Li key={link.type}>
            <Link to={link.url} target="_blank">
              {socialComponent}
            </Link>
          </Li>
        )
      );
    })}
  </Ul>
);

SocialNav.propTypes = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SocialNav;
