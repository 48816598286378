import authenticatedApi from './authenticated-api';
import unauthenticatedApi from './unauthenticated-api';
import { getSessionStorage } from '../get-storage';

const get = ({ path, params, requireAuth = true }) => {
  const api = requireAuth ? authenticatedApi : unauthenticatedApi;
  const sessionStorage = getSessionStorage();
  const impersonatedAs = sessionStorage.getItem('impersonatedAs');

  let apiParams = params;

  if (impersonatedAs) {
    apiParams = { impersonatedAs, ...params };
  }

  return api.request({ path, params: apiParams });
};

const patch = ({ path, data }) => {
  return authenticatedApi.patch({ path, data });
};

const post = ({ path, data }) => {
  return authenticatedApi.post({ path, data });
};

export { get, patch, post };
