import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition, animated } from 'react-spring';

import { Col, Row } from '../grid';
import { P } from '../typography';
import Button from '../button';
import Card from '../card';
import getCurrentUser from '../../utils/selectors/getCurrentUser';
import Link from '../link';
import SVG from '../svg-icon';

import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { ReactComponent as LogOutIcon } from '../../icons/log-out.svg';

const SubMenu = styled(Card)`
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(3rem + 6px);
  width: 14rem;
  z-index: 99;
`;

const AnimatedSubMenu = animated(SubMenu);

const StyledRow = styled(Row)`
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;

  & ~ & {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 0;
  }

  ${SVG} {
    margin-right: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.brandColor};
    ${P} {
      color: white;
    }

    ${SVG} {
      path {
        fill: white;
      }
    }
  }
`;

const UserMenu = ({ visible, toggleVisible }, ref) => {
  const dispatch = useDispatch();

  const { isAdmin } = useSelector(getCurrentUser);

  const onSignOutClicked = () => {
    dispatch.session.signOut();
  };

  const showImpersonationModal = () => {
    toggleVisible();
    dispatch.content.setImpersonationModalVisibility(true);
  };

  const transitions = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedSubMenu ref={ref} data-testid="user-menu" key={key} style={props}>
          <Col width={1}>
            <StyledRow>
              <Button
                data-testid="account-details-icon"
                to="/changing-your-personal-details"
                onClick={toggleVisible}
                variant="wrapper"
                as={Link}
              >
                <SVG as={UserIcon} />
                <P
                  css={css`
                    color: initial;
                  `}
                >
                  Personal Details
                </P>
              </Button>
            </StyledRow>
            <StyledRow>
              <Button data-testid="user-logout-link" onClick={onSignOutClicked} variant="wrapper">
                <SVG as={LogOutIcon} />
                <P>Logout</P>
              </Button>
            </StyledRow>
            {isAdmin && (
              <StyledRow>
                <Button data-testid="user-impersonate-link" onClick={showImpersonationModal} variant="wrapper">
                  <P>Impersonate User</P>
                </Button>
              </StyledRow>
            )}
          </Col>
        </AnimatedSubMenu>
      )
  );
};

export default forwardRef(UserMenu);
