import React from 'react';
import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';
import breakpoint from 'styled-components-breakpoint';

import Button, { ButtonProps } from '../button';

const ClassroomButton = styled(Flex)`
  display: none;
  margin: 0 ${2 / 3}rem;

  ${breakpoint('md')`
    width: 250px;
    display: flex;
  `}

  ${breakpoint('lg')`
    width: 150px;
  `}
`;

/**
 * Online Classroom Tile
 *
 * This component will render an Online Classroom link in the Header.
 *
 * @param {string} title text for button
 * @param {string} url link for button
 */
const OnlineClassroomTile = ({ title, url }) => {
  return (
    <ClassroomButton>
      <Button url={url} target="_blank" small>
        {title}
      </Button>
    </ClassroomButton>
  );
};
OnlineClassroomTile.propTypes = ButtonProps;

export default OnlineClassroomTile;
