import { get } from '../../api';

const contentModel = ({ content = {} }) => ({
  state: content,
  reducers: {
    update: (state, { path, ...payload }) => ({ ...state, [path]: payload }),
    setCurrentPath: (state, currentPath = '') => {
      if (currentPath === '') {
        return { ...state, currentPath: 'home' };
      }

      return { ...state, currentPath };
    },
    setImpersonationModalVisibility: (state, impersonationModalVisibility = false) => ({
      ...state,
      impersonationModalVisibility,
    }),
  },
  effects: dispatch => ({
    async fetch({ path, requireAuth = true, override = {}, callback = () => {} }) {
      const response = await get({
        path: `pages/${path.trim('/')}`,
        requireAuth,
      });

      const { data } = response;

      callback();

      dispatch.content.update({
        path,
        ...data,
        ...override,
      });
    },
  }),
});

export default contentModel;
