import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import initStore from './utils/store';
import Authenticator from './router/authenticator';
import GlobalStyle from './components/global-style';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

function App() {
  // attempt to update the site's document domain to remove any subdomain
  // this is required so that iFrames on the same parent domain
  // can access the parent through cross-site scripting
  useEffect(() => {
    try {
      document.domain = window.location.hostname
        .split('.')
        .slice(1)
        .join('.');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  return (
    <Provider store={initStore()}>
      <BrowserRouter>
        <Authenticator />
      </BrowserRouter>
      <GlobalStyle />
    </Provider>
  );
}

export default App;
