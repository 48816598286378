import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ProcessBlock from '../../components/process-block';
import { Container } from '../../components/grid';

const StyledContainer = styled(Container)`
  flex-grow: 1;
  padding: 4rem 0;
`;

const PageLayout = ({ children }) => (
  <StyledContainer data-testid="onboarding-container">
    {children}
    <ProcessBlock />
  </StyledContainer>
);
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
