import _ from 'lodash';
import { addDays, differenceInCalendarWeeks, format, isFuture, isPast } from 'date-fns';

const TP_NAME_REGEX = /^\w*\s*-?\s*tp\s*(\d+\w*)(\s*-\s*\d+\s*)*$/i;

const transform = tp => {
  let weekNumber = differenceInCalendarWeeks(new Date(), new Date(tp.startDate)) + 1;
  if (weekNumber < 1) {
    weekNumber = null; // TP not started yet
  }
  const match = tp.name.match(TP_NAME_REGEX);
  const teachingPeriod = match ? match[1] : tp.name;
  return {
    startDate: format(new Date(tp.startDate), 'do MMM'),
    teachingPeriod,
    weekNumber,
  };
};

export default tps => {
  if (_.isEmpty(tps)) {
    return [];
  }

  const activeTps = _(tps)
    .filter(tp => isFuture(addDays(new Date(tp.endDate), 1)))
    .orderBy('startDate')
    .value();
  const startedTps = _.filter(activeTps, tp => isPast(new Date(tp.startDate)));
  let tpsToDisplay;
  if (!_.isEmpty(startedTps)) {
    tpsToDisplay = startedTps;
  } else {
    tpsToDisplay = _.compact([_.first(activeTps)]);
  }

  return _.map(tpsToDisplay, transform);
};
