import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { B } from '../typography';
import Button from '../button';
import getCurrentUser from '../../utils/selectors/getCurrentUser';

const BannerContainer = styled.div`
  background-color: #63ff63;
  height: 35px;
  position: fixed; /* added to support older browsers */
  position: sticky;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9;
`;

const BannerText = styled(B)`
  height: 100%;
  line-height: 35px;

  && {
    font-size: 18px;
  }
`;

const CloseButton = styled(Button)`
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
`;

const CloseText = styled(B)`
  text-decoration: underline;

  && {
    font-size: 16px;
  }
`;

const ImpersonationBanner = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const impersonatedAs = get(user, 'impersonatedAs');

  if (!impersonatedAs) {
    return null;
  }

  const { studentId } = impersonatedAs;

  return (
    <BannerContainer>
      <BannerText>{studentId}</BannerText>
      <CloseButton
        variant="wrapper"
        onClick={() => {
          dispatch.user.exitImpersonate();
        }}
      >
        <CloseText>Close</CloseText>
      </CloseButton>
    </BannerContainer>
  );
};

export default ImpersonationBanner;
