import React from 'react';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import styled, { css } from 'styled-components/macro';
import { Box, Flex } from '@rebass/grid';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonProps } from '../button';
import { Col, Row, Container } from '../grid';
import Link from '../link';
import getCurrentUser from '../../utils/selectors/getCurrentUser';
import { ReactComponent as LogoutIcon } from '../../icons/log-out.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { styles } from '../typography';
import { keyboardFocusHighlight } from '../../utils/css';

const Takeover = styled(Box)`
  background: white;
  border-top: solid 1px #f1f1f1;
  bottom: 0;
  box-shadow: 0 500px 0 0 white;
  height: calc(100% - 84px);
  overflow-y: scroll;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1000;

  ${breakpoint('md')`
    height: calc(100% - 74px);
    width: 360px;
  `}

  ${breakpoint('xl')`
    display: none;
  `}
`;

const ButtonCol = styled(Col)`
  padding-top: 1rem;

  ${breakpoint('lg')`
    display: none !important;
  `}
`;

const OnlineClassroomLinks = styled.div``;

const NavigationList = styled(Flex)`
  align-items: center;
  border-top: 1px solid #f1f1f1;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${breakpoint('md')`
    border-top: none;
  `}
`;

const NavigationItem = styled(Flex)`
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
`;

const NavigationLink = styled(Link)`
  ${styles.font({ weight: 'semibold' })};
  align-items: center;
  color: #0a1650;
  display: flex;
  padding: 1.5rem;
  width: 100%;

  svg {
    margin-right: 1rem;
    color: #909090;
  }

  g {
    fill: ${props => props.theme.disabledColor};
  }

  &:hover {
    background-color: ${props => props.theme.brandColor};
    color: ${props => props.theme.white};

    g {
      fill: ${props => props.theme.white};
    }
  }

  ${keyboardFocusHighlight()};
`;

// @TODO: Add some hover styles to Navigation Items.
// @TODO: Add responsive styles to avoid the Takeover showing on larger breakpoints.

const NavTakeover = ({ onlineClassroomLinks, navigations }) => {
  const isVisible = useSelector(state => state.navigation.isVisible);
  const dispatch = useDispatch();

  const { isAdmin } = useSelector(getCurrentUser);

  const toggleVisible = () => {
    dispatch.navigation.toggle();
  };

  const onSignOutClicked = () => {
    dispatch.session.signOut();
  };

  const showImpersonationModal = () => {
    toggleVisible();
    dispatch.content.setImpersonationModalVisibility(true);
  };

  if (!isVisible) return null;

  return (
    <Takeover data-testid="nav-takeover">
      <Container>
        <Row css="flex-direction: column">
          {onlineClassroomLinks.length > 0 && (
            <OnlineClassroomLinks>
              {onlineClassroomLinks.map(({ text, url }) => (
                <ButtonCol key={text} mb="1rem">
                  <Button url={url} target="_blank" small>
                    {text}
                  </Button>
                </ButtonCol>
              ))}
            </OnlineClassroomLinks>
          )}
          <Col>
            <NavigationList>
              {navigations.map(({ text, url, target }) => (
                <NavigationItem key={text}>
                  <NavigationLink to={url} target={target}>
                    {text}
                  </NavigationLink>
                </NavigationItem>
              ))}

              <NavigationItem>
                <NavigationLink data-testid="takeover-search-link" to="/search">
                  <SearchIcon width={20} height={20} />
                  Search
                </NavigationLink>
              </NavigationItem>

              <NavigationItem>
                <NavigationLink data-testid="takeover-account-link" to="/changing-your-personal-details">
                  <UserIcon width={20} height={20} />
                  Personal Details
                </NavigationLink>
              </NavigationItem>

              <NavigationItem>
                <NavigationLink
                  as={Button}
                  css={css`
                    background: none;
                    font-size: inherit;
                    justify-content: left;
                  `}
                  data-testid="takeover-logout-link"
                  onClick={onSignOutClicked}
                  variant="wrapper"
                >
                  <LogoutIcon width={20} height={20} />
                  Logout
                </NavigationLink>
              </NavigationItem>

              {isAdmin && (
                <NavigationItem>
                  <NavigationLink
                    as={Button}
                    data-testid="takeover-user-impersonate-link"
                    onClick={showImpersonationModal}
                    variant="wrapper"
                  >
                    Impersonate User
                  </NavigationLink>
                </NavigationItem>
              )}
            </NavigationList>
          </Col>
        </Row>
      </Container>
    </Takeover>
  );
};

NavTakeover.propTypes = {
  onlineClassroomLinks: PropTypes.arrayOf(PropTypes.shape(ButtonProps)),
  navigations: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

NavTakeover.defaultProps = {
  onlineClassroomLinks: [],
  navigations: [],
};

export default NavTakeover;
