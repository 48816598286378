import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../layouts';
import Pagination from '../../components/search-pagination';
import SearchField from '../../components/search-field';
import SearchResults from '../../components/search-results';
import SearchSummary from '../../components/search-summary';
import getSearchResults from '../../utils/selectors/getSearchResults';
import useScroll from '../../utils/useScroll';

const SearchPage = () => {
  const dispatch = useDispatch();

  const { currentPage } = useSelector(getSearchResults);

  useScroll([currentPage]);

  useEffect(() => {
    dispatch.content.update({
      path: 'search',
      type: { handle: 'searchPage' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <SearchField />
      <SearchSummary />
      <SearchResults />
      <Pagination />
    </Layout>
  );
};

export default SearchPage;
