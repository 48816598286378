import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import trimStart from 'lodash/trimStart';
import URLSearchParams from '@ungap/url-search-params';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Content from './content';
import Page from '../pages/content';
import Preview from '../pages/preview';
import getCurrentUser from '../utils/selectors/getCurrentUser';

const ONBOARDING_PAGE = '/onboarding';
const WELCOME_PAGE = '/welcome';

const AuthenticatedRoute = ({ path }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.navigation.fetch();
  }, [dispatch.navigation, path]);

  return (
    <Switch>
      <Route path={ONBOARDING_PAGE} render={() => <Page path={trimStart(ONBOARDING_PAGE, '/')} />} />
      <Route path={WELCOME_PAGE} render={() => <Page path={trimStart(WELCOME_PAGE, '/')} />} />
      <Content path={path} />
    </Switch>
  );
};
AuthenticatedRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

const Router = withRouter(({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('code');
    urlParams.delete('state');
    history.replace({ pathname: location.pathname, search: urlParams.toString() });
  }, [history, location.pathname, location.search]);

  useEffect(() => {
    dispatch.user.fetch().then(data => {
      const studentId = get(data, 'details.studentId');
      const vfId = data.visualforceId;
      const fname = data.firstName;
      const lname = data.lastName;
      const roles = get(data, 'roles[0].name');

      if (window) {
        // eslint-disable-next-line no-underscore-dangle
        window._studentId = studentId;
        // eslint-disable-next-line no-underscore-dangle
        window._visualforceId = vfId;
        // eslint-disable-next-line no-underscore-dangle
        window._firstName = fname;
        // eslint-disable-next-line no-underscore-dangle
        window._lastName = lname;
        // eslint-disable-next-line no-underscore-dangle
        window._role = roles; /* roles can be : admin, teacher or student */

        if (isFunction(window.dispatchEvent)) {
          if (isFunction(CustomEvent)) {
            window.dispatchEvent(
              new CustomEvent('student_fetched', { detail: { studentId, vfId, fname, lname, roles } })
            );
          } else {
            // IE
            window.dispatchEvent(new Event('student_fetched'));
          }
        }
      }
    });
  }, [dispatch.user]);

  const { name, decConfirmed, orientationAccomplished } = useSelector(getCurrentUser);

  if (!name) return null;

  if (!decConfirmed) {
    if (location.pathname === WELCOME_PAGE) {
      return <AuthenticatedRoute path={location.pathname} />;
    }
    return <Redirect to={WELCOME_PAGE} />;
  }

  if (!orientationAccomplished) {
    if (location.pathname === ONBOARDING_PAGE) {
      return <AuthenticatedRoute path={location.pathname} />;
    }
    return <Redirect to={ONBOARDING_PAGE} />;
  }

  if (location.pathname === WELCOME_PAGE || location.pathname === ONBOARDING_PAGE) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route
        path="/preview/:type/:path"
        render={({ match: { params } }) => <Preview type={params.type} path={params.path} />}
      />
      <AuthenticatedRoute path={location.pathname} />
    </Switch>
  );
});

export { Router as RouterForTest };

export default Router;
