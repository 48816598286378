import { useEffect } from 'react';

// pass through deps as null to ensure the effect will always run
// unless we have specific dependencies
// (an empty array will only run once)
const useScroll = (deps = null) => {
  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    } catch {
      window.scrollTo(0, 0);
    }
    // eslint complains if we pass in the dependency array as a variable,
    // as it can't statically analyse for correctness
    // (ideally we would be using TypeScript or similar to validate deps is an array)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useScroll;
