import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { get as fetch, patch } from '../../api';
import { getSessionStorage } from '../../get-storage';

const impersonatedAsKey = 'impersonatedAs';

const transform = ({ firstName, lastName, details, roles, visualforceId }) => {
  return {
    activeTps: get(details, 'activeTps'),
    decConfirmed: get(details, 'decConfirmed'),
    name: `${firstName} ${lastName}`,
    orientationAccomplished: get(details, 'orientationAccomplished'),
    roles: map(roles, 'name'),
    studentId: get(details, 'studentId'),
    visualforceId,
  };
};

const userModel = ({ user = {} }) => ({
  state: user,
  reducers: {
    update(initialState, newState) {
      const transformedUser = transform(newState);

      if (newState.impersonatedAs) {
        transformedUser.impersonatedAs = transform(newState.impersonatedAs);
      }

      return transformedUser;
    },
    removeImpersonate(state) {
      return omit(state, impersonatedAsKey);
    },
    setError(initialState, error) {
      return {
        ...initialState,
        error,
      };
    },
    setIsLoading(initialState, isLoading) {
      return {
        ...initialState,
        isLoading,
      };
    },
  },
  effects: dispatch => ({
    async fetch({ studentId } = {}, state) {
      dispatch.user.setIsLoading(true);
      dispatch.user.setError(null);

      const response = await fetch({
        path: 'me',
        params: omitBy({ impersonatedAs: studentId }, isUndefined),
      });

      const { data } = response;
      const { title, userId } = data;

      if (studentId) {
        if (!userId) {
          dispatch.user.setError(title);
          dispatch.user.setIsLoading(false);

          return null;
        }

        getSessionStorage(storage => {
          storage.setItem(impersonatedAsKey, studentId);
        });

        dispatch.content.fetch({ path: state.content.currentPath });
        dispatch.lti.clear();
        dispatch.navigation.fetch();
      }

      dispatch.user.setError(null);
      dispatch.user.setIsLoading(false);
      dispatch.user.update(data);
      return data;
    },
    async patch(payload) {
      const response = await patch({
        path: 'me',
        data: payload,
      });

      const { data } = response;

      dispatch.user.update(data);
    },
    exitImpersonate(payload, state) {
      dispatch.user.removeImpersonate();

      getSessionStorage(storage => storage.removeItem(impersonatedAsKey));

      dispatch.content.fetch({ path: state.content.currentPath });
      dispatch.lti.clear();
      dispatch.navigation.fetch();
    },
  }),
});

export default userModel;
