import _ from 'lodash';
import axios from 'axios';

import generateParams from '../generateParams';

export default class Api {
  constructor() {
    this.defaultConfig = {
      headers: {
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
      withCredentials: true,
    };
  }

  static buildQuery(params) {
    return generateParams(params);
  }

  static async handleError(err) {
    if (_.isNil(err.response)) throw err;

    const { status } = err.response;

    switch (status) {
      // TODO: error page layout?
      case 404:
        return {
          data: {
            title: '404',
            body: 'Sorry, we couldn’t find the content you were looking for!',
            type: {
              handle: 'page',
            },
          },
        };
      default:
        // @TODO: integrate with error tracking?
        return {
          data: {
            title: 'Error',
            body: 'Sorry, an unknown error occurred.',
            type: {
              handle: 'page',
            },
          },
        };
    }
  }

  makeRequest(url, { data, method, ...options } = {}) {
    const config = _.defaultsDeep({}, this.defaultConfig, options);

    switch (method) {
      case 'PATCH':
        return axios.patch(url, data, config);
      case 'POST':
        return axios.post(url, data, config);
      case 'GET':
      default:
        return axios.get(url, config);
    }
  }
}
