import React from 'react';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';

import { Block } from '../../components/grid';
import { useToggle } from '../useToggle';

const AccordionContent = styled(Box)`
  margin-top: 2rem;
  width: 100%;

  ${Block} {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const useAccordion = active => {
  const [isActive, toggleActive] = useToggle(active);

  const renderAccordion = content =>
    content ? isActive && <AccordionContent data-testid="accordion-content">{content}</AccordionContent> : null;

  return { isActive, renderAccordion, toggleActive };
};

export { useAccordion };
