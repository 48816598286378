import styled, { css } from 'styled-components/macro';
import breakpoint from 'styled-components-breakpoint';
import { ifProp } from 'styled-tools';

import { fonts, fontWeights } from '../../constants';

const font = (props = {}) => css`
  font-family: ${fonts[props.family || 'sans']};
  font-weight: ${fontWeights[props.weight || 'regular']};
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

const heading = (props = {}) => css`
  ${font(props)}
  line-height: 1.3;
  width: 100%;
`;

// const fontSizes = [16, 18, 21, 24, 30, 36, 48];
const styles = {
  font,

  h1: (props = {}) => css`
    ${heading(props)}
    font-size: 36px;

    ${breakpoint('md')`
      font-size: 42px;
    `}
  `,

  h2: (props = {}) => css`
    ${heading(props)}
    font-size: 30px;

    ${breakpoint('md')`
      font-size: 36px;
    `}
  `,

  h3: (props = {}) => css`
    ${heading(props)}
    font-size: 24px;

    ${breakpoint('md')`
      font-size: 30px;
    `}
  `,

  h4: (props = {}) => css`
    ${heading(props)}
    font-size: 21px;

    ${breakpoint('md')`
      font-size: 24px;
    `}
  `,

  h5: (props = {}) => css`
    ${heading(props)}
    font-size: 16px;
    font-weight: ${fontWeights[props.weight || 'semibold']};

    ${breakpoint('md')`
      font-size: 18px;
    `}
  `,

  p: (props = {}) => css`
    ${font(props)}
    -webkit-font-smoothing: unset;
    -moz-osx-font-smoothing: unset;

    font-size: ${ifProp('small', '14px', '16px')};

    ${breakpoint('md')`
      font-size: ${ifProp('small', '16px', '18px')};
    `}
  `,

  span: (props = {}) => css`
    ${font(props)}
  `,

  b: (props = {}) => css`
    ${font(props)}
    font-weight: ${fontWeights.bold};
  `,
};

const H1 = styled.h1`
  ${props => styles.h1(props)}
`;

const H2 = styled.h2`
  ${props => styles.h2(props)}
`;

const H3 = styled.h3`
  ${props => styles.h3(props)}
`;

const H4 = styled.h4`
  ${props => styles.h4(props)}
`;

const H5 = styled.h5`
  ${props => styles.h5(props)}
`;

const P = styled.p`
  ${props => styles.p(props)}
`;

const Small = styled(P).attrs({ small: true })``;

const Span = styled.span`
  ${props => styles.span(props)}
`;

const B = styled.span`
  ${props => styles.b(props)}
`;

// Export a default object containing the CSS rules for each component,
// which can be used elsewhere (eg: to style child properties in RichText)
export { styles };

// Export named components which use the CSS rules declared above.
export { B, H1, H2, H3, H4, H5, P, Small, Span };
