import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Link from '../link';
import { styles } from '../typography';
import getContent from '../../utils/selectors/getContent';

const Wrapper = styled.nav`
  ${styles.font({ weight: 'semibold' })};
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
  font-size: 12px;
  margin-bottom: 1rem;
  margin-top: 2rem;
  overflow: hidden;
  padding: 1rem 2rem;
`;

const List = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.4);

  :after {
    color: initial;
    content: ' // ';
    display: inline-block;
    font-size: 0.8em;
    margin: 0 1rem;
    opacity: 0.4;
  }

  &:hover {
    color: ${props => props.theme.brandColor};
    text-decoration: none;
  }
`;

const Breadcrumbs = () => {
  const { ancestors, title: currentTitle } = useSelector(getContent);

  if (!currentTitle || _.isEmpty(ancestors)) {
    return null;
  }

  return (
    <Wrapper>
      <List data-testid="breadcrumb-list">
        {ancestors.map(({ title, url }, index) => (
          <li key={_.camelCase(`${url}${index}`)} data-testid={`breadcrumb-link-${index}`}>
            <StyledLink to={url} data-testid={`breadcrumb-link-${url}`} aria-label={title}>
              {title}
            </StyledLink>
          </li>
        ))}
        <li data-testid="breadcrumb-current">{currentTitle}</li>
      </List>
    </Wrapper>
  );
};

export default Breadcrumbs;
