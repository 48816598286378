import head from 'lodash/head';

export default result => {
  const {
    id,
    fields: { title, type, url },
    highlights: { content: body },
  } = result;

  return {
    id,
    body,
    heading: head(title),
    type: head(type),
    url: head(url),
  };
};
