import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import breakpoint from 'styled-components-breakpoint';
import { ifProp, switchProp } from 'styled-tools';
import { Box } from '@rebass/grid';

import { fontWeights } from '../../constants';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import Button, { ButtonProps } from '../button';
import Image, { AssetProps } from '../image';
import RichText from '../rich-text';
import { Block, Col, Row } from '../grid';
import { H3 } from '../typography';
import Video from '../video';

const StyledBlock = styled(Block)`
  /* we do not use a large margin if this block only contains a button */
  margin-bottom: ${ifProp('buttonOnly', '1.5rem', '3rem')};
`;

const ButtonRow = styled(Row)`
  margin-top: 1rem;
`;

const StyledRow = styled(Row)`
  flex-direction: ${switchProp('direction', {
    left: 'column',
    right: 'column-reverse',
  })};

  ${breakpoint('lg')`
    flex-direction: ${switchProp('direction', {
      left: 'row',
      right: 'row-reverse',
    })};
  `}

  /* Box ~ Box will always select the second Box in the DOM, but depending on direction it may not be the second Box rendered */
  ${breakpoint('lg')`
    ${Box} ~ ${Box} {
      ${switchProp('direction', {
        left: 'padding-left: 4rem',
        right: 'padding-right: 4rem',
      })}
    }
  `}
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  left: 1rem;
  height: 100%;
  position: absolute;
  top: 0;

  g {
    fill: ${props => props.theme.white};
  }
`;

const Asset = ({ image, video }) => {
  if (video) return <Video {...video} poster={image} />;

  if (image) return <Image {...image} />;

  return null;
};

Asset.propTypes = {
  image: PropTypes.shape(AssetProps),
  video: PropTypes.shape(AssetProps),
};

Asset.defaultProps = {
  image: undefined,
  video: undefined,
};

const StyledHeading = styled(H3)`
  font-weight: ${fontWeights.semibold};
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const ContentBlock = ({ body, button, download, heading, id, image, imagePosition, video }) => {
  const hasText = !!body || !!heading;
  const hasMedia = !!image || !!video;

  return (
    <StyledBlock buttonOnly={!hasText && !hasMedia}>
      {heading && <StyledHeading data-testid={`block-heading-${id}`}>{heading}</StyledHeading>}
      <StyledRow data-testid={`block-content-${id}`} direction={imagePosition}>
        {hasMedia && (
          <Col width={{ xs: 1, lg: body ? 6 / 12 : 1 }}>
            <Asset {...{ image, video }} />
          </Col>
        )}
        {body && (
          <Col width={{ xs: 1, lg: hasMedia ? 6 / 12 : 11 / 12 }}>
            <RichText>{body}</RichText>
          </Col>
        )}
      </StyledRow>
      {download && (
        <ButtonRow>
          <Col width={{ xs: 1, md: 6 / 12 }}>
            <Button {...download} url={download.fileName} target="_blank" download>
              <StyledDownloadIcon /> {download.alt || `Download`}
            </Button>
          </Col>
        </ButtonRow>
      )}
      {button && (
        <ButtonRow>
          <Col width={{ xs: 1, md: 6 / 12 }}>
            <Button {...button} data-testid="cta-button" />
          </Col>
        </ButtonRow>
      )}
    </StyledBlock>
  );
};

const ContentProps = {
  body: PropTypes.node,
  button: PropTypes.shape(ButtonProps),
  download: PropTypes.shape(AssetProps),
  heading: PropTypes.string,
  id: PropTypes.number.isRequired,
  image: PropTypes.shape(AssetProps),
  imagePosition: PropTypes.oneOf(['left', 'right']),
  video: PropTypes.shape(AssetProps),
};

ContentBlock.propTypes = ContentProps;

ContentBlock.defaultProps = {
  body: undefined,
  button: undefined,
  download: undefined,
  heading: undefined,
  image: undefined,
  imagePosition: 'left',
  video: undefined,
};

export { ContentProps };

export default ContentBlock;
