import { css } from 'styled-components/macro';
import 'what-input';

export const normalise = css`
  border: none;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

export const keyboardFocusHighlight = (size = 4) => css`
  border: ${size}px solid transparent;

  [data-whatintent='keyboard'] &:focus {
    border: ${size}px solid ${props => props.theme.brandColor};
  }
`;
