export default {
  secondaryColor: '#00a6cc',
  backgroundColor: '#f9f9f9',
  brandColor: 'red',
  darkGrey: 'grey',
  disabledColor: '#858585',
  footerColor: '#333092',
  white: 'white',
  successColor: '#85C441',
  categories: {
    academic: 'green',
    marketing: 'red',
    ops: 'blue',
    retention: 'yellow',
  },
};
