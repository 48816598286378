import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Body from '../../components/body';
import Title from '../../components/title';
import ContentMatrix from '../../components/content-matrix';
import Layout from '../../layouts';
import useFetch from '../../utils/useFetch';

const Page = ({ path }) => {
  const dispatch = useDispatch();

  const isFetching = useFetch(path);

  useEffect(() => {
    dispatch.navigation.hide();
  }, [dispatch.navigation, path]);

  if (isFetching) return null;

  return (
    <Layout>
      {path !== 'home' && <Title />}
      <Body />
      <ContentMatrix />
    </Layout>
  );
};

Page.propTypes = {
  path: PropTypes.string.isRequired,
};

export default memo(Page);
