import map from 'lodash/map';
import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import getContent from '../../utils/selectors/getContent';

// @TODO: use _.kebabCase(), no need for a static mapping https://lodash.com/docs/4.17.15#kebabCase
const contentMatrixMapping = {
  accordionBlock: 'accordion-block',
  connectBlock: 'connect-block',
  contentBlock: 'content-block',
  contentImageBlock: 'content-block',
  ctaBlock: 'content-block',
  helpHubBlock: 'helphub-block',
  htmlBlock: 'html-block',
  iframeBlock: 'iframe-block',
  imageBlock: 'content-block',
  ltiBlock: 'lti-block',
  modalPanelBlock: 'modal-panel-block',
  standaloneApps: 'standalone-app',
  keyDatesBlock: 'key-dates-block',
  studentInfoBlock: 'student-information-block',
  tasksBlock: 'task-listing-block',
  tilesBlock: 'tiles-block',
  videoBlock: 'content-block',
};

const ContentMatrix = () => {
  const { contentMatrix } = useSelector(getContent);

  const Matrix = useMemo(
    () =>
      map(contentMatrix, block => {
        const componentPath = contentMatrixMapping[block.type];

        if (!componentPath) return null;

        const Block = React.lazy(() => import(`../${componentPath}`));

        return (
          <Suspense fallback={<></>} key={block.id}>
            <Block data-testid={`block-${block.id}`} {...block} />
          </Suspense>
        );
      }),
    [contentMatrix]
  );

  return Matrix;
};

export default ContentMatrix;
