export default {
  secondaryColor: '#272727',
  backgroundColor: '#f9f9f9',
  brandColor: '#dc2d27',
  darkGrey: '#272727',
  disabledColor: '#858585',
  footerColor: '#272727',
  white: '#ffffff',
  successColor: '#85C441',
};
