import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

import { AccordionHeader } from '../accordion-block';
import Button from '../button';
import Card from '../card';
import ContentBlock, { ContentProps } from '../content-block';
import { Col, Row } from '../grid';
import { H3 } from '../typography';
import { useAccordion } from '../../utils/useAccordion';
import { ReactComponent as Tick } from '../../icons/tick.svg';

const TickIcon = styled(Tick)``;

const Wrapper = styled(Card)`
  border: 1px solid transparent;

  ${ifProp(
    'active',
    css`
      border: 1px solid ${props => props.theme.brandColor};
    `,
    ifProp(
      'completed',
      css`
        background-color: ${props => props.theme.successColor}33;
        /* adding 33 to the hex-code translates to opacity: 20% */
        /* https://stackoverflow.com/questions/15852122/hex-transparency-in-colors */
      `
    )
  )}

  ${ifProp(
    'completed',
    css`
      border: 1px solid ${props => props.theme.successColor};
      color: ${props => props.theme.black};
    `
  )}
`;

const TickContainer = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: end;
  position: absolute;
  right: 2rem;

  ${TickIcon} {
    path {
      fill: ${props => props.theme.successColor};
    }
  }
`;

const ProcessItem = ({ active, completed, heading, id, index, onClick, processBlockItems }) => {
  const { isActive, renderAccordion, toggleActive } = useAccordion(active);

  const completeTask = () => {
    onClick();
    toggleActive();
  };

  return (
    <Wrapper active={isActive} completed={completed} data-testid={`process-item-${id}`}>
      <AccordionHeader
        active={isActive}
        completed={completed}
        data-testid={`accordion-heading-${id}`}
        onClick={toggleActive}
      >
        <Row width={1}>
          <Col
            width={{ xs: 'auto' }}
            css={css`
              padding-right: 1rem;
            `}
          >
            <H3>Step {index + 1}</H3>
          </Col>
          <Col
            css={css`
              margin-right: 3rem;
            `}
          >
            <H3>{heading}</H3>
          </Col>
          <TickContainer>{completed && <TickIcon data-testid={`accordion-tick-${id}`} />}</TickContainer>
        </Row>
      </AccordionHeader>
      {renderAccordion(
        <>
          {processBlockItems.map(item => (
            <ContentBlock key={id} {...item} data-testid={`process-item-content-${item.id}`} />
          ))}
          <Row
            css={css`
              margin-top: 2rem;
            `}
          >
            <Col width={{ xs: 1, md: 6 / 12, lg: 4 / 12 }} offset={{ xs: 0, md: 6, lg: 8 }}>
              <Button onClick={completeTask} data-testid={`process-button-${id}`} variant="action">
                I Understand & Continue
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

ProcessItem.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  processBlockItems: PropTypes.arrayOf(PropTypes.shape(ContentProps)).isRequired,
};

export default ProcessItem;
