import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';

const Row = styled(Flex)`
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  & ~ & {
    margin-top: 1rem;
  }
`;

export default Row;
