import Api from './api';

class UnauthenticatedApi extends Api {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/unauthenticated`;
  }

  async get({ path }) {
    return this.request({ path });
  }

  async request({ path }) {
    try {
      const { data } = await this.makeRequest(`${this.baseUrl}/${path}/${Api.buildQuery()}`);
      return data;
    } catch (error) {
      return Api.handleError(error);
    }
  }
}

export default new UnauthenticatedApi();
