import React from 'react';
import { useSelector } from 'react-redux';

import getPageType from '../utils/selectors/getPageType';
import LoginLayout from './login';
import OnboardingLayout from './onboarding';
import PageLayout from './page';
import SearchLayout from './search';
import WelcomeLayout from './welcome';

const getLayout = handle => {
  switch (handle) {
    case 'loginPage':
      return LoginLayout;
    case 'onboardingPage':
      return OnboardingLayout;
    case 'searchPage':
      return SearchLayout;
    case 'studentDeclarationPage':
      return WelcomeLayout;
    case 'page':
      return PageLayout;
    default:
      return null;
  }
};

// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  const Layout = getLayout(useSelector(getPageType));

  return (
    Layout && (
      <Layout>
        <div id="mainContent" tabIndex="-1" />
        {children}
      </Layout>
    )
  );
};
