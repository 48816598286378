import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import breakpoint from 'styled-components-breakpoint';

import FooterNav from '../footer-nav';
import Image from '../image';
import RichText from '../rich-text';
import SocialNav from '../social-nav';
import getFooter from '../../utils/selectors/getFooter';
import { Col, Row, Container } from '../grid';
import Link from '../link';

export const Wrapper = styled.div`
  background-color: ${props => props.theme.footerColor};
  flex-shrink: 0;
  margin-top: 3rem;
`;

export const StyledContainer = styled(Container)`
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
`;

const Row1 = styled(Row)`
  flex-wrap: wrap;
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

const Logo = styled(Image)`
  margin-bottom: 2rem;

  ${breakpoint('md')`
    margin-bottom: 0;
  `}
`;

const Hr = styled.hr`
  background-color: #e6e6e6;
  border: 0;
  height: 1px;
  margin: 0;
  opacity: 0.4;
  padding: 0;
`;

const Row2 = styled(Row)`
  flex-direction: row;
  padding-bottom: 2rem;
  padding-top: 1.5rem;
`;

// @TODO: Swap the rich text editor to use Small instead (need CMS change).
const StyledRichText = styled(RichText)`
  color: #ffffff;
  opacity: 0.7;
`;

const Footer = () => {
  const { body, image, navigations = [], socialLinks = [] } = useSelector(getFooter);

  return (
    <Wrapper>
      <StyledContainer>
        <Row1>
          {/*
            fontSize 0px is there to avoid white space causing height;
            0px is there to avoid rebass using fontSize array
          */}
          {image && (
            <Col fontSize="0px" width={{ xs: 1, md: 3 / 12 }}>
              <Link to="/">
                <Logo width={{ xs: 1, md: 3 / 4 }} {...image} />
              </Link>
            </Col>
          )}
          <FooterNav navigations={navigations} />
        </Row1>
        <Hr />
        <Row2>
          <Col width={{ xs: 1, md: 9 / 12 }}>{body && <StyledRichText>{body}</StyledRichText>}</Col>
          <Col width={{ xs: 1, md: 3 / 12 }}>
            <SocialNav socialLinks={socialLinks} />
          </Col>
        </Row2>
      </StyledContainer>
    </Wrapper>
  );
};

export default Footer;
