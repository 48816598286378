import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useDispatch, useSelector } from 'react-redux';

import { H1, H5, P, styles } from '../typography';
import { ReactComponent as CloseSvg } from '../../icons/close.svg';
import Button from '../button';

const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: ${props => (props.show ? 'block' : 'none')};
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
`;

const ModalContent = styled.div`
  background: white;
  height: 100%;
  padding: 50% 10%;
  position: relative;
  text-align: center;

  ${breakpoint('md')`
    height: auto;
    left: 50%;
    max-width: 80%;
    width: 50%;
    padding: 5% 10%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
  `}

  ${breakpoint('lg')`
    width: auto;
  `}
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const CloseIcon = styled(CloseSvg)`
  path {
    fill: black;
  }
`;

const Heading = styled(H1)`
  margin-bottom: 2rem;
`;

const StyledInput = styled.input.attrs({ type: 'text' })`
  ${props => styles.h4(props)};
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 2rem 0 1rem;
  padding: 1.5rem 2rem;
  width: 100%;
`;

const ErrorText = styled(P)`
  color: #dc143c;
  margin-bottom: 1rem;
  text-align: left;
`;

const ImpersonationModal = ({ handleClose, show }) => {
  const dispatch = useDispatch();
  const [studentId, setStudentId] = useState('');
  const isLoading = useSelector(state => state.user.isLoading);
  const httpErrorCode = useSelector(state => state.user.error);

  let error = null;

  if (httpErrorCode) {
    if (httpErrorCode === '404') {
      error = 'Student ID not found for impersonation.';
    } else {
      error = 'Something went wrong.';
    }
  }

  useEffect(() => {
    if (isLoading === false && httpErrorCode === null) {
      dispatch.content.setImpersonationModalVisibility(false);
    }
  }, [dispatch.content, httpErrorCode, isLoading]);

  const handleSubmit = async () => {
    if (!studentId) {
      return;
    }

    dispatch.user.fetch({ studentId });
  };

  return (
    <ModalContainer data-testid="modal-container" {...{ show }}>
      <ModalContent>
        <CloseButton variant="icon" onClick={handleClose} data-testid="close-button">
          <CloseIcon />
        </CloseButton>
        <Heading>Impersonate Student</Heading>
        <H5>Warning - do not use impersonation to change student data</H5>
        <StyledInput placeholder="Student ID" value={studentId} onChange={event => setStudentId(event.target.value)} />
        {error && <ErrorText>{error}</ErrorText>}
        <Button variant="action" text={isLoading ? 'Loading...' : 'NEXT'} onClick={handleSubmit} />
      </ModalContent>
    </ModalContainer>
  );
};

ImpersonationModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

ImpersonationModal.defaultProps = {
  handleClose: () => {},
};

export default ImpersonationModal;
