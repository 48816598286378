export default {
  secondaryColor: '#ff8a00',
  backgroundColor: '#f9f9f9',
  brandColor: '#ff8a00',
  darkGrey: '#272727',
  disabledColor: '#858585',
  footerColor: '#001738',
  white: '#ffffff',
  successColor: '#85C441',
};
