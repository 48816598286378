import _ from 'lodash';
import React, { useEffect } from 'react';
import URLSearchParams from '@ungap/url-search-params';
import { withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LANDING_PAGE_STORAGE_KEY } from '../../utils/store/session';
import initCognito from '../../utils/init-cognito';
import Login from '../../pages/login';
import Router from '..';
import withStorageEventListener from '../../components/with-storage-event-listener';
import useRouteChange from '../../utils/useRouteChange';

const Authenticator = withRouter(({ location }) => {
  const dispatch = useDispatch();

  useRouteChange(location.pathname);

  const { isAuthenticated, isAuthenticating } = useSelector(state => state.session);

  useEffect(() => {
    if (!isAuthenticated) {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('code') || urlParams.has('error_description')) {
        const redirectPath = sessionStorage.getItem(LANDING_PAGE_STORAGE_KEY) || window.location.pathname;
        const cognito = initCognito();
        cognito.userhandler = {
          onSuccess: async () => {
            await dispatch.session.signInSuccess();
            window.history.replaceState(null, null, redirectPath);
          },
          onFailure: async error => {
            await dispatch.session.signInFailure(_.replace(decodeURIComponent(error), /\+/g, ' '));
            window.history.replaceState(null, null, window.location.pathname);
          },
        };
        const curUrl = `${window.location.href}?${urlParams.toString()}`;

        cognito.parseCognitoWebResponse(curUrl);
      }
    }
  }, [dispatch.session, isAuthenticated, location.search]);

  if (isAuthenticating) {
    return null;
  }

  if (!isAuthenticated) {
    if (location.pathname === '/login') {
      return <Login />;
    }

    /**
     * If the user is not authenticated and the URL contains UTM parameters,
     * store the landing page with search query in session storage
     * otherwise, store the landing page in session storage
     * If the session storage is not empty, do not update the landing page
     */
    if (!sessionStorage.getItem(LANDING_PAGE_STORAGE_KEY)) {
      const urlParams = new URLSearchParams(location.search);
      if (
        Array.from(urlParams.keys()).length > 0 &&
        Array.from(urlParams.keys()).every(key => key.startsWith('utm_'))
      ) {
        sessionStorage.setItem(LANDING_PAGE_STORAGE_KEY, location.pathname + location.search);
      } else {
        sessionStorage.setItem(LANDING_PAGE_STORAGE_KEY, location.pathname);
      }
    }

    return <Redirect to="/login" />;
  }

  if (location.pathname === '/login') {
    return <Redirect to="/" />;
  }

  const landingPage = sessionStorage.getItem(LANDING_PAGE_STORAGE_KEY);

  if (landingPage) {
    sessionStorage.removeItem(LANDING_PAGE_STORAGE_KEY);
    return <Redirect to={landingPage} />;
  }

  return <Router />;
});

export default withStorageEventListener(Authenticator);
