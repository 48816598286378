import size from 'lodash/size';
import React from 'react';
import { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

export const AssetProps = {
  alt: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
};

const Image = ({ alt, fileName, sources, ...props }) => {
  const srcSet = size(sources) > 0 ? sources.map(({ width, src }) => `${src} ${width}w`).join(',') : undefined;

  return (
    <Box {...props}>
      <img
        alt={alt}
        css={css`
          object-fit: cover;
          object-position: top;
          max-height: 100%;
          max-width: 100%;
        `}
        data-testid={`image-${alt}`}
        srcSet={srcSet}
        src={fileName}
      />
    </Box>
  );
};

Image.propTypes = AssetProps;

Image.defaultProps = {
  sources: [],
};

export default Image;
