import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Container } from '../../components/grid';

const StyledContainer = styled(Container)`
  flex-grow: 1;
`;

const SearchLayout = ({ children }) => <StyledContainer>{children}</StyledContainer>;

SearchLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchLayout;
