import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';

import { H3 } from '../typography';
import { Block, Col, Row } from '../grid';
import Tile, { TileProps } from '../tile';
import RichText from '../rich-text';

const TileContainer = styled(Box)`
  margin-bottom: 1rem;
`;

const TileBlock = ({ body, heading, tiles }) => (
  <Block>
    <Row>
      <Col width={{ xs: 1, lg: 9 / 12 }}>
        {heading && <H3 data-testid="blockHeading">{heading}</H3>}
        {body && <RichText>{body}</RichText>}
      </Col>
    </Row>
    <Row>
      {tiles.map(item => (
        <TileContainer key={item.id} width={{ xs: 1, md: 6 / 12, lg: 4 / 12, xl: 3 / 12 }}>
          <Tile {...item} />
        </TileContainer>
      ))}
    </Row>
  </Block>
);

TileBlock.propTypes = {
  body: PropTypes.string,
  heading: PropTypes.string,
  tiles: PropTypes.arrayOf(PropTypes.shape(TileProps)).isRequired,
};

TileBlock.defaultProps = {
  body: undefined,
  heading: undefined,
};

export default TileBlock;
