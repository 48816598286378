import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import ThemeProvider from './components/theme-provider';

require('dotenv').config();

const themedApp = (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

ReactDOM.render(themedApp, document.getElementById('root'));
