import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import useSearch from '../../utils/useSearch';
import { styles } from '../typography';
import getSearchResults from '../../utils/selectors/getSearchResults';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

const StyledInput = styled.input.attrs({ type: 'text' })`
  ${props => styles.h2(props)};
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-top: 2rem;
  padding: 1.5rem 2rem;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.div`
  bottom: 0;
  cursor: pointer;
  height: 100%;
  /*
    as the parent has a margin-top of 2rem and this element is
    absolute-positioned, shift the icon down by 1rem
  */
  margin-bottom: -1rem;
  position: absolute;
  right: 2rem;
`;

const SearchField = () => {
  const { query } = useSelector(getSearchResults);

  const inputRef = useRef({ value: query });

  const dispatch = useDispatch();

  const performSearch = useSearch(inputRef);

  const [hasInput, setHasInput] = useState(inputRef.current.value);

  const handleSubmit = () => performSearch(inputRef.current.value);

  useEffect(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  const handleClear = () => {
    inputRef.current.value = '';
    dispatch.search.update({ query: '' });
  };

  useEffect(() => {
    setHasInput(inputRef.current.value);
  }, [inputRef.current.value]);

  const handleChange = event => {
    switch (event.keyCode) {
      case 13:
        handleSubmit();
        break;
      case 27:
        handleClear();
        break;
      default:
    }
  };

  return (
    <Wrapper>
      <StyledInput
        aria-label="Enter text, and press enter to perform a search."
        data-testid="search-input"
        ref={inputRef}
        onKeyDown={handleChange}
        onInput={() => setHasInput(inputRef.current.value)}
        placeholder="Search …"
        defaultValue={query || undefined}
        role="search"
      />
      <Icon
        aria-label={hasInput ? 'Clear search field' : ''}
        data-testid={`search-field-icon-${hasInput ? 'clear' : 'search'}`}
        as={hasInput ? CloseIcon : SearchIcon}
        onClick={handleClear}
      />
    </Wrapper>
  );
};

export default SearchField;
