import { CognitoAuth } from 'amazon-cognito-auth-js';

import { getLocalStorage, getSessionStorage } from '../get-storage';

const authData = {
  AppWebDomain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  RedirectUriSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_SIGNIN,
  RedirectUriSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_SIGNOUT,
  TokenScopesArray: ['email', 'openid'],
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
};

const LAST_LOGIN_TYPE_KEY = `${authData.AppWebDomain}.lastLogin.type`;

const getAuthData = type => ({
  ...authData,
  Storage: getSessionStorage(),
  IdentityProvider:
    type === 'student'
      ? process.env.REACT_APP_AWS_COGNITO_IDENTITY_PROVIDER
      : process.env.REACT_APP_AWS_COGNITO_IDENTITY_PROVIDER_FOR_STAFF,
});

export default type => {
  let lastLoginType = type;
  getLocalStorage(storage => {
    if (lastLoginType) {
      // Set value when provided, this should only happens on login
      // Use localStorage so we don't need to propagate this when opening new tabs/windows
      storage.setItem(LAST_LOGIN_TYPE_KEY, lastLoginType);
    } else {
      lastLoginType = storage.getItem(LAST_LOGIN_TYPE_KEY) || 'student';
    }
  });

  if (process.env.NODE_ENV === 'test') {
    return {
      getCachedSession: () => ({
        getIdToken: () => ({
          getJwtToken: () => 'test',
        }),
        isValid: () => true,
      }),
      getCurrentUser: () => 'user',
      getSession: function getSession() {
        this.userhandler.onSuccess();
      },
    };
  }

  const auth = new CognitoAuth(getAuthData(lastLoginType));
  auth.useCodeGrantFlow();
  return auth;
};
