import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';

/**
 * Card
 *
 * Component used on the Dashboard to contain headers, tiles, and other child content.
 *
 */
const Card = styled(Flex)`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 2rem;
  width: 100%;
`;

export default Card;
