import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import breakpoint from 'styled-components-breakpoint';
import { Col, Container, Row } from '../../components/grid';
import DispatchButton from '../../components/dispatch-button';
import Image from '../../components/image';
import { H2, Span } from '../../components/typography';
import Link from '../../components/link';
import getContent from '../../utils/selectors/getContent';
import getHeader from '../../utils/selectors/getHeader';

const StyledContainer = styled(Container)`
  padding: 2rem;
  flex-grow: 1;
`;

const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
`;

const StyledCol = styled(Col).attrs({ width: { xs: 10 / 12 } })`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;

  ${H2} {
    font-size: 3rem;
    text-align: center;
  }
`;

const CheckboxContainer = styled(Box)`
  margin: 0 auto;

  input {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: -0.5rem;

    ${breakpoint('lg')`
        left: -1rem;
    `}
  }
`;

const CheckboxCol = styled(Col).attrs({ width: { sm: 1, lg: 8 / 12 }, offset: { sm: 0, lg: 2 } })`
  padding-left: 2rem;
  position: relative;
`;

const Logo = styled(Image)`
  /* center on mobile and tablet */
  margin: 0 auto;

  ${breakpoint('lg')`
    margin: 0;
  `}
`;

const WelcomeLayout = ({ children }) => {
  const [isChecked, setChecked] = useState(false);

  const { link } = useSelector(getContent);
  const { image } = useSelector(getHeader);

  if (!link || !link.url) return null;

  return (
    <StyledContainer>
      <Row>
        <Col width={{ xs: 1, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
          <Logo {...image} />
        </Col>
      </Row>
      <StyledRow>
        <StyledCol>
          {children}
          <Row
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <CheckboxCol>
              <CheckboxContainer>
                <input
                  data-testid="declaration-checkbox"
                  type="checkbox"
                  onChange={({ target }) => setChecked(target.checked)}
                />
                <Span>
                  I agree to the&nbsp;
                  <Link data-testid="declaration-link" to={link.url} target="_blank">
                    {link.text || 'Terms and Conditions'}
                  </Link>
                  .
                </Span>
              </CheckboxContainer>
            </CheckboxCol>
          </Row>
          <DispatchButton
            data-testid="declaration-complete-button"
            payload={{ decConfirmed: true }}
            disabled={!isChecked}
          >
            Start
          </DispatchButton>
        </StyledCol>
      </StyledRow>
    </StyledContainer>
  );
};

WelcomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WelcomeLayout;
