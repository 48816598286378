import trimStart from 'lodash/trimStart';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getContent from '../selectors/getContent';
import useScroll from '../useScroll';

const useRouteChange = path => {
  const dispatch = useDispatch();

  const { title } = useSelector(getContent);

  useEffect(() => {
    dispatch.content.setCurrentPath(trimStart(path, '/'));
  }, [dispatch.content, path]);

  useEffect(() => {
    // don't update the page title if the user is on the login page or path is undefined.
    if (!path || path === '/login') return;

    if (title) {
      document.title = `Student Hub | ${title}`;
    } else {
      document.title = process.env.REACT_APP_SITE_NAME
        ? `Student Hub | ${process.env.REACT_APP_SITE_NAME}`
        : 'Student Hub';
    }
  }, [title, path]);

  useScroll();
};

export default useRouteChange;
