import styled from 'styled-components/macro';

import Col from './col';
import Container from './container';
import Row from './row';

const Block = styled.div`
  margin-bottom: 3rem;
`;

export { Block, Col, Container, Row };

export default { Block, Col, Container, Row };
