import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';

import Link from '../link';
import { H2 } from '../typography';
import RichText from '../rich-text';
import { keyboardFocusHighlight } from '../../utils/css';

export const TileProps = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

const StyledLink = styled(Link)`
  color: black;

  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled(Flex)`
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-height: 4rem;

  ${H2} {
    color: ${props => props.theme.brandColor};
    padding-bottom: 1rem;
  }

  &:hover {
    color: ${props => props.theme.brandColor};

    ${H2} {
      text-decoration: underline;
    }
  }

  ${keyboardFocusHighlight(1)};
`;

/**
 * SearchTile
 *
 * This component will render a heading and body for search results.
 * The SearchTile will link through to the selected article.
 *
 * @param {string} body a short excerpt or description of the linked article
 * @param {string} url URL for the article
 * @param {string} heading the heading of the article
 */

const generateTestid = (obj, url) => `tile-${obj}-${url.replace(/(^[/])/g, '')}`;

const SearchTile = ({ id, body, url, heading }) => (
  <StyledLink data-testid={`search-tile-${id}`} to={url}>
    <Wrapper>
      <H2 weight="semibold" data-testid={generateTestid('heading', url)}>
        {heading}
      </H2>
      <RichText small data-testid={generateTestid('body', url)}>
        {body}
      </RichText>
    </Wrapper>
  </StyledLink>
);

SearchTile.propTypes = TileProps;

export default SearchTile;
