import React, { useRef, useState } from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled, { css } from 'styled-components/macro';
import { Box, Flex } from '@rebass/grid';
import { useSelector, useDispatch } from 'react-redux';
import { ifProp } from 'styled-tools';

import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as MenuIcon } from '../../icons/menu.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as SettingsCogIcon } from '../../icons/settings-cog.svg';

import NavTakeover from '../nav-takeover';
import getHeader from '../../utils/selectors/getHeader';
import Button from '../button';
import { Col, Container } from '../grid';
import PrimaryNavigationBlock from '../primary-navigation-block';
import OnlineClassroomBlock from '../online-classroom-block';
import UserMenu from '../user-menu';
import Link from '../link';
import Image from '../image';
import useClickOutside from '../../utils/useClickOutside';

const Wrapper = styled(Flex)`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  height: 84px;

  ${breakpoint('md')`
    height: 74px;
  `}

  ${breakpoint('xl')`
    height: 76px;
  `}
`;

const HeaderIcon = styled(Box)`
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 3rem;
  height: 100%;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  svg {
    height: 100%;
  }
`;

const DesktopIcons = styled(Box)`
  display: none;

  ${breakpoint('lg')`
    display: flex;
  `}
`;

const MobileIcons = styled(HeaderIcon)`
  border-left: none;
  display: flex;

  ${breakpoint('lg')`
    display: none;
  `}
`;

const styledIcon = ({ isActive, ...props }) => css`
  cursor: pointer;

  path {
    fill: ${() => props.theme.disabledColor};
  }

  &:hover {
    path {
      fill: ${() => props.theme.brandColor};
    }
  }

  ${ifProp(
    'isActive',
    css`
      path {
        fill: ${() => props.theme.brandColor};
      }
    `
  )}
`;

const StyledSearchIcon = styled(({ isActive, ...props }) => <SearchIcon {...props} />)`
  ${props => styledIcon(props)};
`;

const StyledSettingsIcon = styled(({ isActive, ...props }) => <SettingsCogIcon {...props} />)`
  ${props => styledIcon(props)};
`;

const LogoLink = styled(Link)`
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  padding: 0.5rem 0;
  width: 200px;
`;

/**
 * A full-width Header component.
 *
 * This component will render the Header on each page, which will contain separate
 * Logo, PrimaryNavigation, Button, SearchButton and UserNavButton components.
 *
 * @param {string} TBD
 */
const Header = () => {
  // @TODO: Move menu to it's own "thing",
  // since we will want to hook into route change.

  // @TODO: Tidy this up, perhaps make variable name more consistent...
  // const [isMenuOpen, toggleMenu] = useToggle(false);
  const isMenuOpen = useSelector(state => state.navigation.isVisible);

  // @TODO: Update how user menu works to be consistent with nav.
  const [showUserMenu, setUserMenu] = useState(false);

  const menuRef = useRef();

  useClickOutside(menuRef, () => setUserMenu(false));

  const dispatch = useDispatch();

  const toggleNavTakeover = () => {
    dispatch.navigation.toggle();
  };

  const { image, onlineClassroomLinks, navigations } = useSelector(getHeader);

  return (
    <Wrapper data-testid="header">
      <Container>
        <Flex css="align-items: center; height: 100%;" width="1">
          {image && (
            <LogoLink to="/">
              <Image width={{ xs: 1 }} {...image} />
            </LogoLink>
          )}
          <Col css="flex-grow: 1; height: 100%;">
            <PrimaryNavigationBlock navigations={navigations} />
          </Col>
          <OnlineClassroomBlock buttons={onlineClassroomLinks} />
          <DesktopIcons>
            <HeaderIcon>
              <Button data-testid="header-search-icon" as={Link} to="/search" variant="icon">
                <StyledSearchIcon />
              </Button>
            </HeaderIcon>
            <HeaderIcon ref={menuRef}>
              <Button data-testid="header-user-icon" onClick={() => setUserMenu(!showUserMenu)} variant="icon">
                <StyledSettingsIcon isActive={showUserMenu} />
              </Button>
              <UserMenu visible={showUserMenu} toggleVisible={() => setUserMenu(!showUserMenu)} />
            </HeaderIcon>
          </DesktopIcons>
          <MobileIcons>
            <Button data-testid="mobile-menu-onclick-handler" onClick={toggleNavTakeover} variant="icon">
              {isMenuOpen ? (
                <CloseIcon aria-label="Close Menu" data-testid="mobile-menu-close" width={21} height={21} />
              ) : (
                <MenuIcon aria-label="Open Menu" data-testid="mobile-menu-open" width={21} height={21} />
              )}
            </Button>
          </MobileIcons>
          <NavTakeover onlineClassroomLinks={onlineClassroomLinks} navigations={navigations} />
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Header;
