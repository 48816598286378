import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Col, Container, Row } from '../../components/grid';

const StyledContainer = styled(Container)`
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
`;

const StyledCol = styled(Col).attrs({ width: { xs: 10 / 12 } })`
  align-items: center;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
`;

const LoginLayout = ({ children }) => (
  <StyledContainer>
    <StyledRow>
      <StyledCol>{children}</StyledCol>
    </StyledRow>
  </StyledContainer>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
