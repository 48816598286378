import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';

import Button from '../button';

const DispatchButton = ({ children, payload, ...props }) => {
  const dispatch = useDispatch();

  return (
    <Button
      css={css`
        margin-top: 2rem;
        max-width: 24rem;
      `}
      onClick={() => dispatch.user.patch(payload)}
      {...props}
      variant="action"
    >
      {children}
    </Button>
  );
};

DispatchButton.propTypes = {
  children: PropTypes.node.isRequired,
  payload: PropTypes.shape().isRequired,
};

export default DispatchButton;
