import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import 'what-input';

const GlobalStyle = createGlobalStyle`
  ${reset}

  :hover, a:visited, a:link, a:active {
    text-decoration: none;
  }

  [data-whatintent='mouse'] *:focus {
    outline: none;
  }  

  html {
    ${breakpoint('xs')` /* and sm */
      font-size: 12px;
    `}
    ${breakpoint('md')` /* and lg */
      font-size: 16px;
    `}
    ${breakpoint('xl')`
      font-size: 18px;
    `}

    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.darkGrey};
  }

  a {
    color: ${props => props.theme.brandColor};
    font-weight: 600;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default GlobalStyle;
