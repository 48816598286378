/**
 * Breakpoint variables for - https://github.com/rebassjs/grid
 */

const breakpoints = [
  320, // xs
  576, // sm
  768, // md
  992, // lg
  1200, // xl
  1335, // xxl
];

/**
 * Aliases for breakpoints, we need to keep the above structure
 * as well for Rebass's array props
 */
/* eslint-disable prefer-destructuring */
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];
/* eslint-enable */

export default breakpoints;

/*
// spacing units:
xs - sm: 12px
m - lg 16px
xl 18px
xxl 24px
*/
