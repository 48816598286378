import _ from 'lodash';

import { getLocalStorage } from '../../get-storage';
import initCognito from '../../init-cognito';

const CREDENTIALS_FLUSH = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.CREDENTIALS_FLUSH`;
const CREDENTIALS_SHARING = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.CREDENTIALS_SHARING`;
const REQUESTING_SHARED_CREDENTIALS = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.REQUESTING_SHARED_CREDENTIALS`;
export const LANDING_PAGE_STORAGE_KEY = 'portal.landingPage';

export default ({
  session = {
    isAuthenticated: !_.isNil(initCognito().getCurrentUser()),
    isAuthenticating: _.includes(window.location.href, '?code=') && _.isNil(initCognito().getCurrentUser()),
  },
}) => ({
  state: session,
  reducers: {
    update(state, newState) {
      return newState;
    },
  },
  effects: dispatch => ({
    signInFailure(error) {
      dispatch.session.update({ error, isAuthenticated: false, isAuthenticating: false });
    },
    signInSuccess() {
      dispatch.session.update({ isAuthenticated: true, isAuthenticating: false });
    },
    signIn(type) {
      dispatch.session.update({ isAuthenticated: false, isAuthenticating: true });
      const cognito = initCognito(type); // @WARNING: only set type on login
      cognito.clearCachedTokensScopes(); // Try to avoid invalid grant error
      cognito.getSession();
    },
    signOut() {
      const cognito = initCognito();
      cognito.signOut();
      getLocalStorage(storage => {
        storage.setItem(CREDENTIALS_FLUSH, Date.now().toString());
        storage.removeItem(CREDENTIALS_FLUSH);
      });
    },
    askForSharedCredentials() {
      getLocalStorage(storage => {
        storage.setItem(REQUESTING_SHARED_CREDENTIALS, Date.now().toString());
        storage.removeItem(REQUESTING_SHARED_CREDENTIALS);
      });
    },
    onStorageChange(event) {
      const cognito = initCognito();
      if (event.key === REQUESTING_SHARED_CREDENTIALS && cognito.getCurrentUser()) {
        const sharedCredentials = JSON.stringify(cognito.getCachedSession());
        localStorage.setItem(CREDENTIALS_SHARING, sharedCredentials);
        localStorage.removeItem(CREDENTIALS_SHARING);
      }

      if (event.key === CREDENTIALS_SHARING && !cognito.getCurrentUser() && event.newValue) {
        const credentials = JSON.parse(event.newValue);
        const keyPrefix = `CognitoIdentityServiceProvider.${credentials.accessToken.payload.client_id}`;
        const tokenUserName = credentials.accessToken.payload.username;
        const idTokenKey = `${keyPrefix}.${tokenUserName}.idToken`;
        const accessTokenKey = `${keyPrefix}.${tokenUserName}.accessToken`;
        const refreshTokenKey = `${keyPrefix}.${tokenUserName}.refreshToken`;
        const lastUserKey = `${keyPrefix}.LastAuthUser`;
        const scopeKey = `${keyPrefix}.${tokenUserName}.tokenScopesString`;
        const scopesArray = credentials.tokenScopes.tokenScopes;
        const scopesString = scopesArray.join(' ');
        sessionStorage.setItem(idTokenKey, credentials.idToken.jwtToken);
        sessionStorage.setItem(accessTokenKey, credentials.accessToken.jwtToken);
        sessionStorage.setItem(refreshTokenKey, credentials.refreshToken.refreshToken);
        sessionStorage.setItem(lastUserKey, tokenUserName);
        sessionStorage.setItem(scopeKey, scopesString);
        dispatch.session.update({ isAuthenticated: true, isAuthenticating: false });
      }

      if (event.key === CREDENTIALS_FLUSH && cognito.getCurrentUser()) {
        // @NOTE: if logout from another tab/window, remembers the path here,
        // so that the user can sign back in and still get the pre-existing page
        sessionStorage.setItem(LANDING_PAGE_STORAGE_KEY, window.location.href);
        cognito.clearCachedTokensScopes();
        dispatch.session.update({ isAuthenticated: false, isAuthenticating: false });
      }
    },
  }),
});
