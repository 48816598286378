import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Block, Col, Row } from '../grid';
import SearchTile from '../search-tile';
import usePagination from '../../utils/usePagination';

const SearchResults = () => {
  const { visibleResults } = usePagination();

  if (isEmpty(visibleResults)) return null;

  return (
    <Block data-testid="search-results">
      <Row>
        <Col data-testid="search-results-list" width={{ xs: 1, lg: 9 / 12 }}>
          {visibleResults.map(item => (
            <SearchTile key={item.id} {...item} />
          ))}
        </Col>
      </Row>
    </Block>
  );
};

export default SearchResults;
