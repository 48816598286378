import size from 'lodash/size';
import slice from 'lodash/slice';
import times from 'lodash/times';
import { useDispatch, useSelector } from 'react-redux';

import getSearchResults from '../selectors/getSearchResults';

const usePagination = () => {
  const { currentPage, perPage, results } = useSelector(getSearchResults);
  const dispatch = useDispatch();

  const maxPage = Math.ceil(size(results) / perPage);

  const increment = () => dispatch.search.setCurrentPage(Math.min(currentPage + 1, maxPage));
  const decrement = () => dispatch.search.setCurrentPage(Math.max(currentPage - 1, 1));
  const select = page => dispatch.search.setCurrentPage(Math.min(page, maxPage));

  return {
    currentPage,
    increment,
    decrement,
    select,
    pages: times(maxPage, num => num + 1),
    visibleResults: slice(results, (currentPage - 1) * perPage, currentPage * perPage),
  };
};

export default usePagination;
