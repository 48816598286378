import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Col, Row } from '../grid';
import { H1 } from '../typography';
import getContent from '../../utils/selectors/getContent';

const StyledH1 = styled(H1).attrs({ weight: 'semibold' })`
  margin: 2rem 0;
`;

// eslint-disable-next-line react/prop-types
const Title = ({ offset, width, ...props }) => {
  const { title } = useSelector(getContent);

  if (!title) return null;

  return (
    <Row>
      <Col {...{ offset, width }}>
        <StyledH1 data-testid="title" {...props} aria-live="polite" aria-atomic="true">
          {title}
        </StyledH1>
      </Col>
    </Row>
  );
};

export default Title;
