import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Flex } from '@rebass/grid';
import { ifProp } from 'styled-tools';

import Card from '../card';
import ContentBlock, { ContentProps } from '../content-block';
import { Col, Row } from '../grid';
import { H3, P } from '../typography';
import Button from '../button';
import { ReactComponent as Chevron } from '../../icons/chevron.svg';
import { useAccordion } from '../../utils/useAccordion';

const Arrow = styled(Chevron)`
  transform-origin: center;
  transition: transform 420ms cubic-bezier(0.1, 0.9, 0.3, 1);

  ${ifProp(
    'active',
    css`
      transform: rotate(180deg);

      path {
        fill: ${props => props.theme.brandColor};
      }
    `
  )}
`;

export const AccordionHeader = styled(Flex)`
  align-items: center;
  cursor: pointer;
  flex: 1;
  justify-content: space-between;

  /* add negative margin and padding to expand the clickable area */
  margin: -2rem;
  padding: 2rem;

  ${ifProp(
    'active',
    css`
      ${H3} {
        color: ${props => props.theme.brandColor};
      }
    `
  )}
`;

const Wrapper = styled(Card)`
  border: 1px solid transparent;

  ${ifProp(
    'active',
    css`
      /* override default hover state using !important */
      border: 1px solid ${props => props.theme.darkGrey} !important;
    `
  )}

  &:hover {
    border: 1px solid ${props => props.theme.brandColor};

    ${Arrow} {
      path {
        fill: ${props => props.theme.brandColor};
      }
    }

    ${AccordionHeader} {
      ${H3} {
        color: ${props => props.theme.brandColor};
      }
    }
  }
`;

const AccordionBlock = ({ accordionItems, heading, id, subHeading }) => {
  const { isActive, renderAccordion, toggleActive } = useAccordion(false);

  // required because styled-components will not accept boolean props
  const active = isActive ? 1 : undefined;

  return (
    <Wrapper active={active}>
      <AccordionHeader active={active} data-testid={`accordion-heading-${id}`} onClick={toggleActive}>
        <Row>
          <Col width={1}>
            <H3>{heading}</H3>
          </Col>
          {subHeading && (
            <Col width={1}>
              <P>{subHeading}</P>
            </Col>
          )}
        </Row>
        <Button aria-label={isActive ? 'Collapse' : 'Expand'} variant="icon">
          <Arrow active={active} data-testid={`accordion-chevron-${id}`} />
        </Button>
      </AccordionHeader>
      {renderAccordion(accordionItems.map(item => <ContentBlock key={item.id} {...item} />))}
    </Wrapper>
  );
};

AccordionBlock.propTypes = {
  accordionItems: PropTypes.arrayOf(PropTypes.shape(ContentProps)).isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  subHeading: PropTypes.string,
};

AccordionBlock.defaultProps = {
  subHeading: undefined,
};

export default AccordionBlock;
