import _ from 'lodash';
import { get } from '../../api';
import transformResult from '../../transformResult';

const RESULTS_PER_PAGE = 6;

const searchModel = ({ search = {} }) => ({
  state: { perPage: RESULTS_PER_PAGE, currentPage: 1, ...search },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setCurrentPage(state, currentPage) {
      return {
        ...state,
        currentPage,
      };
    },
  },
  effects: dispatch => ({
    async fetch({ query, callback = () => {} }) {
      const searchResults = await get({ path: 'search', params: { q: query }, requireAuth: true });

      callback();

      const { meta, data } = searchResults;

      dispatch.search.update({
        found: _.get(meta, 'found', 0),
        currentPage: 1,
        query,
        results: _.map(data, transformResult),
      });
    },
  }),
});

export default searchModel;
