import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

const StyledBox = styled(Box)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
`;

// Returns the margin as a % for a given offset
// The offset can be an array/object of int (breakpoints)
// or a single int across all breakpoints.
const getMarginForOffset = offset => {
  const fn = value => `${(value / 12) * 100}%`;
  if (_.isArray(offset)) {
    return _.map(offset, fn);
  }
  if (_.isObject(offset)) {
    return _.mapValues(offset, fn);
  }

  // String + Number use cases.
  const intValue = _.toInteger(offset);
  if (_.isInteger(intValue)) {
    return fn(intValue);
  }

  return undefined;
};

const Col = ({ children, offset, ...props }) => {
  const ml = getMarginForOffset(offset);

  return (
    <StyledBox ml={ml} {...props}>
      {children}
    </StyledBox>
  );
};

Col.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

Col.defaultProps = {
  children: null,
  offset: [],
};

export default Col;
