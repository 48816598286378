import React from 'react';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import { useSelector } from 'react-redux';

import Button, { ButtonProps } from '../button';
import { styles } from '../typography';
import getContent from '../../utils/selectors/getContent';

const Tile = styled(Box)`
  ${styles.font({ weight: 'semibold' })};
  align-items: center;
  color: ${props => props.theme.brandColor};
  cursor: pointer;
  justify-content: center;
`;

const useActiveTab = ({ title, url }) => {
  const { ancestors = [], title: currentTitle } = useSelector(getContent);

  const isAncestor = ancestors.find(ancestor => ancestor.url === url);

  const isCurrent = currentTitle === title;

  return isAncestor || isCurrent;
};

/**
 * Primary Navigation Tile
 *
 * This component will render a navigation link in the Header.
 *
 * @param {string} TBD
 */
const PrimaryNavigationTile = ({ target, title, url, ...props }) => {
  const active = useActiveTab({ title, url });

  return (
    <Tile {...props}>
      <Button
        data-testid={`navigation-tile-${title}`}
        css="padding: 0 0.5rem;"
        url={url}
        target={target}
        isActive={active}
        variant="navigation"
      >
        {title}
      </Button>
    </Tile>
  );
};
PrimaryNavigationTile.propTypes = ButtonProps;

export default PrimaryNavigationTile;
