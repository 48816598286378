import { useState } from 'react';

const useToggle = value => {
  const [state, setState] = useState(value);

  const toggleState = () => setState(!state);

  return [state, toggleState];
};

export { useToggle };
