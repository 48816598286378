export default {
  secondaryColor: '#3c3c3c',
  backgroundColor: '#f9f9f9',
  brandColor: '#006dae',
  darkGrey: '#3c3c3c',
  disabledColor: '#858585',
  footerColor: '#3c3c3c',
  white: '#ffffff',
  successColor: '#85C441',
};
